import React, { useEffect } from "react";
import { createFileRoute, useParams } from "@tanstack/react-router";
import useSearch from "~/hooks";
import { useGetToken } from "~/api/codegen/liquidityComponents";
import { ArrowLeft, CopyIcon } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";
import { truncateString } from "~/libs/string.helper";
import { notify } from "~/components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Switch } from "~/@/components/ui/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/@/components/ui/tooltip";
import { Button } from "~/@/components/ui/button";
import { AppTable } from "~/components/table/table";
import { TablePagination } from "~/components/organisms/table/table-pagination";
import { CardFooter } from "~/components/layouts/Card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import { CardRoot } from "~/components/layouts/Card";
import { TabButton } from "~/components/Tabs";
import TransactionsFilters from "~/components/TransactionsFilters";
import { EmptyStateDescription } from "~/components/molecules/empty-state";
import { useCountries } from "~/hooks/use-countries";
import { Divider } from "~/components/Divider";
import { EmptyStateOverlay } from "~/components/molecules/empty-state";
import { WalletTransactionModal } from "~/components/TransactionDetails";
import { CountryI } from "~/general/interfaces";
import {
  useAllBusinessTransactions,
  useGetBusinessId,
} from "~/hooks/use-business";
import { statusColumn } from "~/components/table/transaction";
import {
  amountColumn,
  initiatorColumn,
  transactionTypeColumn,
  receiverColumn,
  senderColumn,
  createdAtColumn,
  statusDotColumn,
} from "~/components/table/transaction";
import { useModal } from "~/hooks/use-modal";
import { useWallets, WalletHolderImpl } from "~/hooks/use-wallet";
import { DefaultInputField } from "~/components/Inputs";
import { EditPaymentLink } from "~/components/Modal/EditPaymentLink";

export const Route = createFileRoute("/dashboard/wallet/spend/token/$token")({
  component: TokenDetails,
});

function TokenDetails() {
  const navigate = useNavigate();
  const token = useParams({ from: Route.id });
  const { data, isLoading, error } = useGetToken(token?.token);
  const modal = useModal();

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  // Debug React Query state
  useEffect(() => {
    console.log("Token:", token);
    console.log("Data:", data);
    console.log("Loading:", isLoading);
    console.error("Error:", error);
  }, [token, data, isLoading, error]);

  if (!token) {
    console.error("Token parameter is missing from the URL.");
    return <div>Error: Token parameter is missing.</div>;
  }

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.payload}</div>;

  function formatDateAndTime(isoDate: string): JSX.Element {
    const date = new Date(isoDate);

    // Format the date as "Wed 12, Nov 2023"
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);

    // Format the time as "4:00:00pm"
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    return (
      <div className="flex flex-row items-center">
        <span className="font-bold">{formattedDate}</span>
        <div className="w-2" />
        <span className="text-sm text-gray-500">{formattedTime}</span>
      </div>
    );
  }

  function TransactionLog({ title }: { title?: string }) {
    const [currentTab, setCurrentTab] = useState(undefined);
    const { data: countries } = useCountries();
    const [page, setPage] = useState<number>(1);
    const [open, setOpen] = React.useState(false);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [currency, setCurrency] = React.useState<CountryI>(
      countries.filter((val: CountryI) => val.iso2 === "US")[0],
    );
    const [currencyChanged, setCurrencyChanged] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");
    const [transactionMethod, setTransactionMethod] = useState<string>("");
    const [transactionStatus, setTransactionStatus] = useState<string>("");
    const [amount, setAmount] = React.useState<string>("");
    const [amountRange, setAmountRange] = React.useState<string>("");
    const [showFilter, setShowFilter] = React.useState<boolean>(false);
    const modal = useModal();
    const businessId = useGetBusinessId();
    const { data: wallet } = useWallets();

    const wallets = Array.from(
      WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
    );

    const table_columns = useMemo(
      () => [
        statusDotColumn,
        createdAtColumn,
        senderColumn,
        receiverColumn,
        initiatorColumn,
        transactionTypeColumn,
        amountColumn,
        statusColumn,
      ],
      [],
    );
    const filteredItems = useMemo(
      () => [
        {
          title: "StartDate",
          value: fromDate ? format(fromDate, "dd/MM/yy") : "",
        },
        {
          title: "EndDate",
          value: toDate ? format(toDate, "dd/MM/yy") : "",
        },
        { title: "Method", value: transactionMethod ?? "" },
        { title: "Status", value: transactionStatus ?? "" },
        { title: "Currency", value: selectedCurrency ?? "" },
        { title: "Amt", value: amount ? `${amountRange}${amount}` : "" },
      ],
      [
        transactionMethod,
        fromDate,
        toDate,
        transactionStatus,
        currency,
        amount,
      ],
    );

    const filteredTab = useMemo(() => {
      let tab = "";

      if (currentTab === "all") {
        tab = "";
      }

      if (currentTab === "funding") {
        tab = "funding";
      }
      if (currentTab === "payout") {
        tab = "payout";
      }
      if (currentTab === "earnings") {
        tab = "earnings";
      }
      if (currentTab === "withdrawal") {
        tab = "withdrawal";
      }
      return tab;
    }, [currentTab]);

    const handleCurrencyChange = (newCurrency: CountryI) => {
      if (currency !== newCurrency) {
        setSelectedCurrency(newCurrency.currency);
      }
    };

    const { isLoading, data, refetch } = useAllBusinessTransactions(
      {
        businessId,
        page: page,
        wallets: wallets.map((w) => w.id).join(","),
        currency:
          open === false && showFilter && selectedCurrency
            ? selectedCurrency
            : "",
        amount:
          open === false && showFilter && amount && amountRange
            ? `${amountRange}${amount}`
            : "",
        startDate:
          open === false && showFilter && fromDate
            ? `${fromDate?.split("/")?.reverse()?.join("-")}`
            : "",
        endDate:
          open === false && showFilter && toDate
            ? `${toDate?.split("/")?.reverse()?.join("-")}`
            : "",
        flowType:
          (open === false && showFilter && transactionMethod
            ? transactionMethod !== "all"
              ? transactionMethod.toUpperCase()
              : ""
            : "") ||
          (open === false && filteredTab
            ? filteredTab.toUpperCase()
            : undefined),
        status:
          open === false && showFilter && transactionStatus
            ? transactionStatus
            : "",
      },
      {
        enabled: !!businessId && fromDate && open === false,
      },
    );

    const clearFilter = () => {
      setCurrentTab("all");
      setOpen(false);
      setShowFilter(false);
      setTransactionMethod("");
      setTransactionStatus("");
      setAmount("");
      setAmountRange("");
      setFromDate("");
      setToDate("");
      setCurrency(countries.filter((val: CountryI) => val.iso2 === "US")[0]);
      setSelectedCurrency("");
      refetch();
    };

    const handleFilter = () => {
      setCurrentTab("all");
      setOpen(false);
      setShowFilter(true);
    };

    const { searchTerm, handleSearchChange, resetSearch, filteredList } =
      useSearch(data.data, "flow_type", "method");

    const tabs = [
      { label: "All", value: "all", isActive: true },
      { label: "Funding", value: "funding", isActive: true },
      { label: "Payout", value: "payout", isActive: true },
      { label: "Withdrawal", value: "withdrawal", isActive: true },
      { label: "Earnings", value: "earnings", isActive: true },
    ];

    return (
      <Tabs defaultValue={"all"} className="h-full overflow-scroll">
        <CardRoot>
          <div
            className={
              "no-scrollbar flex w-full flex-col items-start justify-between gap-4 overflow-x-auto px-4 pb-2 pt-5 md:flex-row"
            }
          >
            <div className="flex flex-wrap items-center gap-4 md:flex-nowrap">
              <h1>Recent Transactions</h1>
            </div>
          </div>

          <Divider className="my-[5px]" />

          <div className="no-scrollbar h-[80%] overflow-scroll">
            <AppTable
              data={filteredList ?? []}
              isLoading={isLoading}
              columns={table_columns}
              onRowClick={(row) => {
                modal.show("transaction_details", data.data[row.index]);
              }}
            />
          </div>

          {data.meta.total !== 0 && (
            <CardFooter className="py-3">
              <TablePagination
                data={{
                  page: data.meta.page,
                  pageCount: data.meta.pageCount,
                  pageSize: data.meta.pageSize,
                  total: data.meta.total,
                  setPage: setPage,
                }}
              />
            </CardFooter>
          )}
          <WalletTransactionModal />
        </CardRoot>
      </Tabs>
    );
  }

  return (
    <div className="ml-5 mt-3 overflow-x-hidden">
      <ArrowLeft
        className="mb-2 h-6 w-6 cursor-pointer text-gray-500"
        onClick={() => navigate({ to: "/dashboard/wallet/spend" })}
      />
      <h1>Token Payment</h1>
      <div className="flex w-full items-start justify-between overflow-x-hidden">
        <div className="flex w-[45%] flex-col gap-4 overflow-x-hidden">
          <div className="item-center flex w-full justify-between">
            <div>URL</div>
            <div
              style={{ fontSize: "14px", fontWeight: "light" }}
              className="flex items-center overflow-hidden"
            >
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#0094FF", textDecoration: "underline" }}
                className="text-underline cursor-pointer overflow-ellipsis font-body font-light"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   e.preventDefault();
                //   window.open(data?.data.url, "_blank");
                // }}
              >
                {truncateString(`${data?.data.url}`, 15)}
              </a>
              <div className="z-2 pl-2">
                <CopyToClipboard
                  text={`${data?.data.url}`}
                  onCopy={() => {
                    notify("success", "Checkout link copied");
                  }}
                >
                  <button type="button" className="p-1 font-bold">
                    <CopyIcon className="h-4 w-4" color="#0094FF" />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div>Status</div>
            <div>
              {data.data.application.valid ? (
                <div className="flex items-center gap-2 text-green-500">
                  Active
                  <Switch
                    className="data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-red-500"
                    checked={true}
                  />
                </div>
              ) : (
                <div className="flex items-center gap-2 text-red-500">
                  Inactive
                  <Switch
                    className="data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-red-500"
                    checked={false}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div>Amount</div>
            <div className="flex items-center font-body font-bold">
              $ {data.data.application.metadata.extra.amount}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger className="-mt-3 mr-3 pl-3">
                    {data.data.application.metadata.extra.amountLocked ? (
                      <img
                        src="/lock.svg"
                        alt="Lock"
                        className="absolute h-[16px] w-[16px]"
                      />
                    ) : (
                      <img
                        src="/unlock.svg"
                        alt="Lock"
                        className="absolute h-[16px] w-[16px]"
                      />
                    )}
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {data.data.application.metadata.extra.amountLocked
                        ? "Amount locked"
                        : "Amount unlocked"}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div>Data Collection</div>
            <div>
              {data.data.application.metadata.extra.dataCollections.map(
                (dataCollection) => {
                  return (
                    <span className="ml-2 rounded-md border border-gray-200 bg-transparent px-2 py-1 text-xs">
                      {dataCollection}
                    </span>
                  );
                },
              )}
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div>Type of Link</div>
            <div className="text-sm">{"Checkout Widget"}</div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div>Created At</div>
            <div>{formatDateAndTime(data.data.application.createdAt)}</div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div>Expires At</div>
            <div>{formatDateAndTime(data.data.application.expiredAt)}</div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div>Partner Context</div>
            <div className="flex w-[75%] items-center justify-end">
              <div className=" whitespace-pre-wrap break-words bg-transparent px-0 font-mono text-xs">
                {JSON.stringify(data.data.application.metadata.extra, null, 3)}
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-around gap-4">
            <Button
              variant="destructive"
              className="h-auto rounded-sm px-10 py-1 text-sm"
            >
              Delete
            </Button>
            <Button
              variant="outline"
              className="h-auto rounded-sm px-10 py-1 text-sm"
              onClick={() => {
                console.log("opening edit modal");
                setIsShowEditModal(true);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
        <div className="h-[70vh] w-[53%]">
          <TransactionLog title="Token Payment" />
          {isShowEditModal && (
            <EditPaymentLink
              sourceTable={""}
              selectedData={data.data}
              isShow={isShowEditModal}
              setIsShow={setIsShowEditModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}
