import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Tabs } from "~/@/components/ui/tabs";
import {
  useChangePassword,
  useUpdateAnAccount,
} from "~/api/codegen/walletosComponents";
import { AlertTriangleIcon } from "~/assets/icons/AlertTriangleIcon";
import { ArrowTopRight } from "~/assets/icons/ArrowTopRight";
import { CaretDownFilled } from "~/assets/icons/CaretDownFilled";
import { ArrowLeft, ArrowRight, PlusIcon } from "lucide-react";
import { DEFAULT_COUNTRY } from "~/constants";
import { CheckCircleIcon } from "~/assets/icons/CheckCircleIcon";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { Divider } from "~/components/Divider";
import {
  DefaultInputField,
  PhoneNumberInput,
  ToggleInput,
} from "~/components/Inputs";
import { PasswordInput } from "~/components/Inputs/password";
import { PasswordValidator } from "~/components/Inputs/password-validator";
import { getRoleInfo } from "~/components/Redux/state/user/hooks";
import { notifyApiError, notifySuccess } from "~/components/Toast";
import { SubTextMedium, TitleRegular } from "~/components/Typography";
import { Badge } from "~/components/atoms/Badge";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardRoot,
} from "~/components/layouts/Card";
import {
  SecondaryContent,
  SecondaryNav,
  SecondaryRoot,
  SecondaryTab,
} from "~/components/layouts/SecondaryNav";
import {
  TitleHeading,
  TitleRoot,
  TitleSubHeading,
} from "~/containers/Dashboard/Pages/Settings/Title";
import {
  useAccountData,
  useBusiness,
  useBusinessMetadata,
} from "~/hooks/use-business";
import { getInitials } from "~/libs";
import { password_validator, phoneNumberSchema } from "~/libs/validators";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { useLoaders } from "~/components/Redux/state/loader/hooks";
import {
  fetchEnableMfa,
  fetchVerifyMfa,
  fetchDisableMfa,
} from "~/api/codegen/walletosComponents";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notify } from "~/components/Toast";
import { OTPInput } from "~/components/Inputs";
import { on } from "events";
import React from "react";
import { DefaultButton, DefaultButtonOutline } from "~/components/Buttons";
import { cons } from "effect/List";

interface TwoFAProps {
  onBack: () => void;
}

interface BackupProps {
  onBack: () => void;
}

interface MfaData {
  secretCode?: string;
  backupCodes?: string[];
}

interface SecurityProps {
  onToggleTwoFA: () => void;
  onToggleBackup: () => void;
}

export function UserProfile() {
  const { data: userDetails } = useAccountData();
  const update_account = useUpdateAnAccount();
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [phoneNumber, setPhoneNumber] = useState("");

  const { data } = useBusinessMetadata();
  const businessDetails = data.business;

  const onSubmit = async (data) => {
    try {
      const formattedNumber = data.phoneNumber.startsWith("0")
        ? data.phoneNumber.substring(1)
        : data.phoneNumber;

      const response = await update_account.mutateAsync({
        pathParams: { accountId: userDetails?.id },
        body: {
          phone: `${country.phone_code}${formattedNumber}`,
        },
      });
      notifySuccess(response);
      // form.reset();
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <>
      <TitleRoot>
        <TitleHeading>Profile</TitleHeading>
        {/* <TitleSubHeading>Manage your personal information</TitleSubHeading> */}
      </TitleRoot>

      <CardRoot className="mb-8">
        <CardBody
          className={"flex flex-col-reverse gap-4 md:flex-row md:gap-0"}
        >
          <div className="md:w-[60%]">
            <div>
              <span className="text-2xl font-bold uppercase leading-10 text-zinc-700">
                {userDetails?.firstName} {userDetails?.lastName}
              </span>
              <div className="flex items-center justify-between">
                <span className="mt-[4px] flex items-center">
                  <span className="text-xs font-normal leading-tight text-gray-400">
                    {businessDetails?.legalName}
                  </span>
                </span>
                <div className="inline-flex h-6 w-[108px] items-center justify-center gap-2 rounded-2xl bg-green-50 px-3 py-2">
                  <CheckCircleIcon />
                  <span className="text-center font-body text-xs font-bold leading-tight text-green-500">
                    Active
                  </span>
                  <CaretDownFilled color="#3BB75E" />
                </div>
              </div>
            </div>
            <Divider />

            <div className="mt-[20px] flex items-center justify-between">
              <DefaultInputField
                label="First name"
                value={userDetails?.firstName}
                setValue={(e) => {}}
                disabled
                containerStyle={{ width: "47%" }}
              />
              <DefaultInputField
                label="Last name"
                value={userDetails?.lastName}
                setValue={(e) => {}}
                disabled
                containerStyle={{ width: "47%" }}
              />
            </div>

            <Divider />

            <DefaultInputField
              label="Role"
              value={getRoleInfo(userDetails)}
              setValue={(e) => {}}
              disabled
              containerStyle={{ marginTop: "10px", marginBottom: "25px" }}
            />
            <DefaultInputField
              label="Email"
              value={userDetails?.email}
              setValue={(e) => {}}
              disabled
            />
            <Divider />
            <PhoneNumberInput
              label="Phone number"
              value={phoneNumber}
              country={country}
              setCountry={(e) => {
                setCountry(e);
              }}
              setValue={(e) => {
                setPhoneNumber(e);
              }}
            />
          </div>
          <div className="flex items-start justify-center md:w-[40%]">
            <div className="flex h-[180px] w-[180px] items-center justify-center rounded-[50%] bg-[#008EF4] xl:h-[300px] xl:w-[300px] xl:rounded-[300px]">
              <span className="text-[80px] font-bold text-[white] xl:text-[120px]">
                {getInitials(
                  `${userDetails?.firstName} ${userDetails?.lastName}`,
                )}
              </span>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <Button
            variant={"default"}
            disabled={!phoneNumber}
            onClick={() => {
              onSubmit({ phoneNumber });
            }}
          >
            <LoadingButtonContent loading={update_account.isPending}>
              Save changes
            </LoadingButtonContent>
          </Button>
        </CardFooter>
      </CardRoot>
    </>
  );
}

export function BusinessProfile() {
  const { data } = useBusinessMetadata();
  const businessDetails = data?.business;

  return (
    <>
      <TitleRoot>
        <TitleHeading>Business</TitleHeading>
        {/* <TitleSubHeading>Manage your business information</TitleSubHeading> */}
      </TitleRoot>

      <CardRoot className="mb-8">
        <CardBody className={"flex flex-col gap-4 md:flex-row md:gap-0"}>
          <div className="flex items-start justify-center md:w-[40%]">
            <div className="flex h-[180px] w-[180px] items-center justify-center rounded-[50%] bg-[#008EF4] xl:h-[300px] xl:w-[300px] xl:rounded-[300px]">
              <span className="text-[80px] font-bold text-[white] xl:text-[120px]">
                {getInitials(businessDetails?.legalName)}
              </span>
            </div>
          </div>

          <div className="md:w-[60%]">
            <div className="flex flex-col">
              <p className="text-2xl font-bold leading-10 text-zinc-700">
                {businessDetails?.legalName}
              </p>
              <div className="flex items-center justify-between">
                <span className="mt-[4px] flex items-center">
                  <span className="text-xs font-normal leading-tight text-gray-400">
                    {businessDetails?.slug}
                  </span>
                </span>
                <Badge type="success">Active</Badge>
              </div>
            </div>
            <Divider />
            <DefaultInputField
              label="Business name"
              value={businessDetails?.legalName}
              setValue={(e) => {}}
              disabled
              containerStyle={{ marginTop: "25px", marginBottom: "25px" }}
            />
            <DefaultInputField
              label="Industry"
              value={businessDetails?.industry}
              setValue={(e) => {}}
              disabled
            />
            <DefaultInputField
              label="Business email"
              value={businessDetails?.contactEmail}
              setValue={(e) => {}}
              disabled
              containerStyle={{ marginTop: "25px", marginBottom: "25px" }}
            />
            <DefaultInputField
              label="Website"
              value={businessDetails?.website}
              setValue={(e) => {}}
              disabled
            />
          </div>
        </CardBody>
      </CardRoot>
    </>
  );
}

const schema = z.object({
  current_password: password_validator,
  new_password: password_validator,
});

export const Security: React.FC<SecurityProps> = ({
  onToggleTwoFA,
  onToggleBackup,
}) => {
  const change_password = useChangePassword();
  const { data: userDetails, refetch, isLoading } = useAccountData();
  const [toggle, setToggle] = useState<boolean>(false);
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    setToggle(userDetails.mfaEnabled);
  }, [userDetails, isLoading]);

  const toggleAction = async () => {
    if (userDetails.mfaEnabled) {
      await fetchDisableMfa({
        body: {
          email: userDetails.email,
        },
      });
      await refetch();
      notify("success", "2FA disabled successfully");
    } else {
      await refetch();
      onToggleTwoFA();
    }
  };

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const response = await change_password.mutateAsync({
        body: {
          previous: data.current_password.trim(),
          proposed: data.new_password.trim(),
        },
      });
      notifySuccess(response);
      form.reset();
    } catch (err) {
      notifyApiError(err);
    }
  });

  return (
    <>
      <TitleRoot>
        <TitleHeading>Security</TitleHeading>
        {/* <TitleSubHeading>
          Secure your account and business access
        </TitleSubHeading> */}
      </TitleRoot>

      <CardRoot>
        <Form {...form}>
          <CardBody
            className={"flex flex-col-reverse gap-4 md:flex-row md:gap-0"}
          >
            <div className="flex-1 space-y-4">
              <div className="mb-4 font-body text-base leading-normal text-stone-600">
                Change your Password
              </div>

              <FormField
                name="current_password"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Current Password</FormLabel>
                      <FormControl>
                        <PasswordInput {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                name="new_password"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>New Password</FormLabel>
                      <FormControl>
                        <PasswordInput {...field} />
                      </FormControl>
                      <PasswordValidator password={field.value} />
                    </FormItem>
                  );
                }}
              />
            </div>

            <div className="mb-8 flex flex-1 flex-col md:items-start md:justify-start md:pl-[65px]">
              <div className="flex w-[100%] items-center justify-between md:mt-[40px]">
                <span className="flex items-center gap-1">
                  <span className="text-[#4576C2]">
                    <AlertTriangleIcon />
                  </span>
                  <SubTextMedium>Two Factor Authentication</SubTextMedium>
                </span>

                <ToggleInput
                  isToggled={toggle}
                  setIsToggled={(e) => {
                    setToggle(e);
                  }}
                  onToggle={() => {
                    toggleAction();
                  }}
                />
              </div>
              <span className="mt-[15px] flex cursor-pointer items-center">
                {/* <p
                  style={{
                    color: userDetails.mfaEnabled ? "#4576C2" : "#A0A0A0",
                    marginRight: "2px",
                    cursor: userDetails.mfaEnabled ? "pointer" : "not-allowed",
                  }}
                  onClick={() => {
                    if (userDetails.mfaEnabled) {
                      onToggleBackup();
                    }
                  }}
                  className={`text-xs font-bold leading-tight ${
                    userDetails.mfaEnabled ? "text-zinc-700" : "text-gray-200"
                  }`}
                >
                  Click here to generate new backup codes
                </p> */}
                {/* <span
                  className={`${
                    userDetails.mfaEnabled ? "text-[#4576C2]" : "text-gray-200"
                  }`}
                >
                  <ArrowTopRight size={12} />
                </span> */}
              </span>
            </div>
          </CardBody>

          <CardFooter>
            <Button variant={"default"} onClick={onSubmit}>
              <LoadingButtonContent loading={change_password.isPending}>
                Save changes
              </LoadingButtonContent>
            </Button>
          </CardFooter>
        </Form>
      </CardRoot>
    </>
  );
};

export const TwoFA: React.FC<TwoFAProps> = ({ onBack }) => {
  const [mfaData, setMfaData] = useState<MfaData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [otpUrl, setOtpUrl] = useState("");
  const { data: userDetails, refetch, isLoading: loading } = useAccountData();
  const { showEllipsisLoader, closeEllipsisLoader } = useLoaders();
  const [view, switchView] = useState<"2fa" | "otp">("2fa");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    enableTwoFA();
  }, [userDetails]);

  useEffect(() => {
    refetch();
  }, [userDetails, loading]);

  async function enableTwoFA() {
    const data = {
      email: userDetails.email,
      action: "generate_secret",
    };
    if (userDetails.mfaEnabled === false) {
      try {
        setIsLoading(true);
        // @ts-expect-error
        const res = await fetchEnableMfa({ body: data });
        setMfaData(res);
        setOtpUrl(
          // @ts-expect-error
          `otpauth://totp/CentryOS:${userDetails.email}?secret=${res.secretCode}&issuer=GradientFi&algorithm=SHA1&digits=6&period=30`,
        );
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  function switcher(view: "2fa" | "otp") {
    switchView(view);
  }

  async function verifyTOTP() {
    if (otp.length !== 6) {
      notify("error", "Invalid OTP");
    } else {
      showEllipsisLoader();
      try {
        const res = await fetchVerifyMfa({
          body: {
            email: userDetails.email,
            code: otp,
          },
        });
        if (res.status === "SUCCESS") {
          await fetchEnableMfa({
            // @ts-expect-error
            body: {
              email: userDetails.email,
              action: "enable",
            },
          });
          await refetch();
          closeEllipsisLoader();
          notify("success", "2FA enabled successfully");
          onBack();
        } else {
          notify("error", res.message);
          closeEllipsisLoader();
        }
      } catch (err) {
        notifyApiError(err);
        closeEllipsisLoader();
      }
    }
  }

  return (
    <>
      <CardRoot className="mb-10 mt-4" size="half">
        {" "}
        <CardHeader className="flex items-center justify-between px-10 font-semibold">
          <ArrowLeft
            className="cursor-pointer"
            onClick={() => {
              const res = fetchDisableMfa({
                body: {
                  email: userDetails.email,
                },
              });
              onBack();
            }}
            size={"1.2rem"}
          />
          <p>Enable 2FA</p>
        </CardHeader>
        <CardBody className="mb-[10px] flex flex-col px-10">
          {view === "2fa" && (
            <>
              <div>
                <ol className="list-decimal text-sm tracking-[0.015em]">
                  <li className="mb-2">
                    Install an authenticator app like Authy or Google
                    Authenticator to use 2FA.
                  </li>
                  <li>
                    Scan the QR code or use the authentication key to generate a
                    code from your authenticator app.
                  </li>
                </ol>
              </div>
              {isLoading && mfaData == null ? (
                <div className="mt-[35px] flex h-[300px] w-[100%] flex-col items-center justify-center">
                  <div className="mb-[30px] h-[250px] w-[250px] animate-pulse bg-muted" />
                  <div className="ml-[5px] flex items-center justify-start gap-4 rounded-2xl bg-muted p-2">
                    <div className="h-6 w-40 animate-pulse bg-muted" />
                    <div className="h-6 w-6 animate-pulse bg-muted" />
                  </div>
                </div>
              ) : (
                <div className="mt-[35px] flex h-[300px] w-[100%] flex-col items-center justify-center">
                  <img
                    alt={"QR Code"}
                    className="mb-[30px] h-[250px] w-[250px]"
                    src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                      otpUrl,
                    )}&size=150x150`}
                  />
                  <div className="ml-[5px] flex items-center justify-start gap-4 rounded-2xl bg-sky-100 p-2">
                    <div className="font-body text-sm font-bold leading-normal tracking-wide text-[#0094FF] opacity-80">
                      {mfaData?.secretCode?.slice(0, 10)}****{" "}
                      {mfaData?.secretCode?.slice(40)}
                    </div>
                    <CopyToClipboard
                      text={`${mfaData?.secretCode}`}
                      onCopy={() => notify("success", "2FA Secret Key copied!")}
                    >
                      <span className="cursor-pointer">
                        <CopyIcon h="16" w="16" color="#0094FF" />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
            </>
          )}
          {view === "otp" && (
            <div>
              <div className="flex flex-col items-center justify-center gap-2">
                <p className="my-[20px] font-medium">
                  Enter 6 digit code from your authenticator app
                </p>
                <OTPInput value={otp} setValue={setOtp} />
                <div className="mb-[40px] mt-[30px] inline-flex h-5 w-[118px] items-center gap-0.5">
                  {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                  <div
                    className="cursor-pointer font-body text-xs font-normal leading-tight hover:text-[#0094FF]"
                    onClick={() => {
                      switcher("2fa");
                      setOtp("");
                    }}
                  >
                    Can't get code?
                  </div>
                  <div className="flex h-3 w-3 items-center justify-center p-[0.38px]">
                    <div className="relative h-[11.25px] w-[11.25px]" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter className="justify-center px-24">
          <Button
            className="w-full"
            variant={"default"}
            onClick={() => {
              if (view === "2fa") {
                switcher("otp");
              } else if (view === "otp") {
                verifyTOTP();
              }
            }}
          >
            Continue
          </Button>
        </CardFooter>
      </CardRoot>
    </>
  );
};

export const Backup: React.FC<BackupProps> = ({ onBack }) => {
  const { data: userDetails } = useAccountData();
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    generateBackupCode();
  }, [userDetails]);

  const downloadBackupCodes = () => {
    const element = document.createElement("a");
    const file = new Blob([backupCodes.join("\n")], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "backup_codes.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  async function generateBackupCode() {
    if (userDetails.mfaEnabled === false) {
      notify("error", "You need to enable 2FA to generate backup codes");
    } else {
      try {
        const res = await fetchEnableMfa({
          // @ts-expect-error
          body: {
            email: userDetails.email,
            action: "generate_backup",
          },
        });
        // @ts-expect-error
        setBackupCodes(res?.backupCodes);
        setLoading(false);
      } catch (err) {
        notifyApiError(err);
      }
    }
  }

  return (
    <>
      <CardRoot className="mb-10 mt-4" size="half">
        {" "}
        <CardHeader className="flex items-center justify-center px-10 font-semibold">
          <p>Security Code</p>
        </CardHeader>
        <CardBody className="mb-[10px] flex flex-col px-10 py-4">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TitleRegular className="text-center">
              Save 10-digits backup codes to your account
            </TitleRegular>
            <div className="mb-[15px] mt-[15px] inline-flex h-64 w-[324px] justify-between rounded-2xl bg-emerald-200 bg-opacity-30 px-6 py-4">
              {loading ? (
                <>
                  <div className="inline-flex flex-col items-start justify-center gap-4">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div
                        key={index + 1}
                        className="h-6 w-24 animate-pulse rounded bg-gray-300"
                      />
                    ))}
                  </div>
                  <div className="inline-flex flex-col items-start justify-center gap-4">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div
                        key={index + 1}
                        className="h-6 w-24 animate-pulse rounded bg-gray-300"
                      />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="inline-flex flex-col items-start justify-center gap-4">
                    {backupCodes.slice(0, 5).map((code, index) => (
                      <div
                        key={index + 1}
                        className="text-base font-bold leading-normal text-sky-800"
                      >
                        {code}
                      </div>
                    ))}
                  </div>
                  <div className="inline-flex flex-col items-start justify-center gap-4">
                    {backupCodes.slice(5).map((code, index) => (
                      <div
                        key={index + 1}
                        className="text-base font-bold leading-normal text-sky-800"
                      >
                        {code}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </CardBody>
        <CardFooter className="mx-auto flex-col justify-center px-24">
          <DefaultButton
            textClassName="text-gray-600"
            className="mb-[10px] w-[390px]"
            icon={<PlusIcon color="white" size="16" />}
            label="Download backup codes"
            onClick={() => {
              downloadBackupCodes();
            }}
          />
          <DefaultButtonOutline
            textClassName="text-[#008EF4]"
            className="mb-[20px] w-[390px] !text-[#008EF4]"
            label="I've backup the codes"
            onClick={() => onBack()}
          />
        </CardFooter>
      </CardRoot>
    </>
  );
};
