import { cva } from "class-variance-authority";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/@/components/ui/accordion";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogRemote,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { TransactionKV } from "~/general/transaction";
import { useModalHandle } from "~/hooks/use-modal";
import { simpleCurrencyFormatter } from "~/libs/currency.helpers";
import { safeFormat } from "~/libs/date.helpers";
import {
  BankDetailsFactory,
  TrxFactory,
  TrxImpl,
  TrxLog,
  TxDestinationBusinessWallet,
  TxDestinationUserWallet,
} from "~/libs/factories/transaction";
import { StatusBadge } from "../Badges";
import { Divider } from "../Divider";
import { DialogModalHeader } from "../Modal";

import {
  createFileRoute,
  Link,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import {
  CardBackButton,
  CardBody,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { Textarea } from "~/@/components/ui/textarea";
import { Alert } from "~/components/atoms/Alert";
import { Checkbox } from "~/@/components/ui/checkbox";
import { useForm } from "react-hook-form";
import { ChipSelector } from "~/components/Inputs";
import { notify, notifyApiError } from "~/components/Toast";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "~/@/components//ui/form";
import { AmountInput } from "~/components/Inputs";
import { Input } from "~/@/components/ui/input";
import { Button } from "~/@/components/ui/button";
import { CountryI } from "~/general/interfaces";
import { query_client } from "~/contexts/react-query";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import {
  useCreateApplicationTokenAccount,
  useGetTokens,
} from "~/api/codegen/liquidityComponents";
import { useGetBusinessId } from "~/hooks/use-business";
import { useCountries } from "~/hooks";

import { Label } from "~/@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "~/@/components/ui/radio-group";
import { PlusIcon } from "~/assets/icons/PlusIcon";

export const Route = createFileRoute(
  "/dashboard/wallet/spend/paymentLinkSetup",
)({
  component: () => <Page />,
});

function Page() {
  return (
    <section className="flex justify-center py-12">
      <Content />
    </section>
  );
}

function Content() {
  const router = useRouter();
  return (
    <>
      <CardRoot size="lg" className="px-2">
        <CardHeader className="flex justify-between gap-2 ">
          <Link className="flex items-center">
            <CardBackButton
              onClick={() => {
                router.history.back();
              }}
            />
          </Link>
          <CardTitle className="text-xl">Create Payment Link</CardTitle>
        </CardHeader>
        <CardSeparator className="-mx-2" />
        <PaymentlinkForm />
      </CardRoot>
    </>
  );
}

const schema = z.object({
  name: z.string(),
  context: z.string(),
  currency: z.string(),
  amount: z.string(),
  amountLocked: z.boolean(),
  url: z.string().optional(),
});
const dataList = ["Name", "Email", "Phone"];

const PaymentlinkForm = () => {
  const navigate = useNavigate();
  const businessId = useGetBusinessId();
  const { data: countries } = useCountries();

  const [list, setList] = React.useState<string[]>([]);
  const [currency, setCurrency] = React.useState<CountryI>();
  const form = useForm<z.infer<typeof schema>>();

  const { data, refetch } = useGetTokens(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        type: "CHECKOUT_WIDGET",
        page: 1,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );
  const mutation = useCreateApplicationTokenAccount();
  console.log(currency);

  useEffect(() => {
    console.log("countries", countries);
    if (!currency) {
      const currency = countries?.filter((country) => country?.iso2 === "US");
      form.setValue("currency", currency[0].currency as string);
      setCurrency(currency[0]);
    }
  }, [currency]);

  const handleDuplicateName = (name: string) => {
    console.log("dataaa", data);
    const duplicate = data?.data?.find(
      (item) => item.application?.metadata?.extra?.slug === name,
    );
    if (duplicate) {
      console.log("duplicate found", duplicate);
      notify("error", "Payment link name already exists!");
      return true;
    }
    return false;
  };

  return (
    <>
      <form
        className="mx-auto flex flex-col px-6"
        onSubmit={form.handleSubmit(async (data) => {
          try {
            if (!data.currency) {
              notify("error", "Select a currency!");
              return;
            }

            if (handleDuplicateName(data.name)) {
              return;
            }

            console.log("did it make it here?", data);

            await mutation.mutateAsync({
              pathParams: { businessId },
              body: {
                tokenType: "CHECKOUT_WIDGET",
                currency: currency.currency,
                extra: {
                  slug: data.name,
                  amount: data.amount,
                  amountLocked: data.amountLocked ?? true,
                  dataCollections: list,
                },
              },
            });

            await refetch();
            notify("success", "Payment link created successfully!");
            navigate({ to: "/dashboard/wallet/spend" });
          } catch (err) {
            notifyApiError(err);
          }
        })}
      >
        <Form {...form}>
          <div className="mt-[10px] flex w-full flex-col gap-4">
            <FormField
              control={form.control}
              name={"name"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Payment name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Enter link name" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={"amount"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Amount</FormLabel>
                    <FormControl>
                      <AmountInput
                        country={currency}
                        setCountry={(value) => {
                          setCurrency(value);
                          form.setValue("currency", value.currency as string);
                        }}
                        setValue={(value) => {
                          form.setValue(field.name, value as string);
                        }}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={"amountLocked"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel
                      className={"flex items-center gap-x-2 text-gray-500"}
                    >
                      <FormControl>
                        <Checkbox
                          onCheckedChange={(value) => {
                            form.setValue(field.name, value as boolean);
                          }}
                          checked={field.value ?? true}
                        />
                      </FormControl>
                      <span className={"font-normal"}>Lock account</span>
                    </FormLabel>
                  </FormItem>
                );
              }}
            />

            <CardSeparator className="my-2" />

            <FormField
              control={form.control}
              name={"url"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Data collection</FormLabel>
                    <FormControl>
                      <ChipSelector
                        className="w-full"
                        list={dataList}
                        selected={list}
                        setSelected={setList}
                        placeholder="Select data you want to accept"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <CardSeparator className="my-2" />
            <FormField
              control={form.control}
              name={"url"}
              render={({ field }) => {
                return (
                  <FormItem className="pb-3">
                    <FormLabel>Customize URL</FormLabel>
                    <FormControl className="flex items-center justify-between">
                      <div className="flex items-center justify-between gap-x-2 text-black">
                        <Input
                          {...field}
                          placeholder="https://centryos.xyz/"
                          disabled
                          className="mr-1 w-[40%] rounded-md border border-black bg-gray-100"
                        />
                        <Input
                          {...field}
                          placeholder="https://centryos.xyz/"
                          className="w-[60%]"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={"url"}
              render={({ field }) => {
                return (
                  <FormItem className="pb-3">
                    <FormLabel>Type of link</FormLabel>
                    <FormControl className="flex items-center justify-start">
                      <RadioGroup
                        defaultValue="option-one"
                        className="flex flex-row items-center justify-start gap-y-2"
                      >
                        <div className="mr-4 flex items-center space-x-2">
                          <RadioGroupItem value="option-one" id="option-one" />
                          <Label htmlFor="option-one">Option One</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="option-two" id="option-two" />
                          <Label htmlFor="option-two">Option Two</Label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={"url"}
              render={({ field }) => {
                return (
                  <FormItem className="pb-3">
                    <FormLabel>Expiration date</FormLabel>
                    <FormControl className="flex items-center justify-start">
                      <div className="flex items-center justify-between">
                        <Input
                          {...field}
                          placeholder="12 months"
                          className="w-[50%] rounded-r-none border-r-0 border-black bg-gray-100"
                        />
                        <Input
                          {...field}
                          placeholder="12 months"
                          className="w-[50%] rounded-l-none border-l-0 border-black"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name={"url"}
              render={({ field }) => {
                return (
                  <FormItem className="pb-3">
                    <FormLabel>Type of link</FormLabel>
                    <FormControl className="flex items-center justify-start">
                      <RadioGroup
                        defaultValue="option-one"
                        className="flex flex-row items-center justify-start gap-y-2"
                      >
                        <div className="mr-4 flex items-center space-x-2">
                          <RadioGroupItem value="option-one" id="option-one" />
                          <Label htmlFor="customers">Customers</Label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <div className="flex w-full items-center justify-between">
              <button
                className="flex items-center gap-x-2 rounded-md border border-gray-500 px-2 py-1 text-sm"
                type="button"
              >
                <PlusIcon color="black" />
                Advanced configurations
              </button>
            </div>
          </div>

          <CardSeparator className="-mx-8" />
          <div className="my-[20px] flex items-center justify-between">
            <Button
              type={"reset"}
              variant="outline"
              size={"lg"}
              onClick={() => {
                navigate({ to: "/dashboard/wallet/spend" });
              }}
            >
              Cancel
            </Button>
            <Button
              type={"submit"}
              size={"lg"}
              className=""
              disabled={!form.formState.isDirty || !form.formState.isValid}
            >
              <LoadingButtonContent loading={mutation.isPending}>
                Next
              </LoadingButtonContent>
            </Button>
          </div>
        </Form>
      </form>
    </>
  );
};

export function NewPaymentLinkModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const modal = useModalHandle("new_payment_link");

  return (
    <DialogRemote
      id={"new_payment_link"}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <DialogContent
        showClose={false}
        className="h-[90vh] w-full overflow-hidden overflow-scroll font-body"
      >
        <div>
          <PaymentlinkForm />
        </div>
      </DialogContent>
    </DialogRemote>
  );
}
