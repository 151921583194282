import { cva } from "class-variance-authority";
import { ComponentPropsWithoutRef } from "react";
import { FiatCurrencyI } from "~/constants/currencies";
import { formatNumber } from "~/libs/currency.helpers";
import { CardBody, CardRoot } from "./layouts/Card";
import { PlusIcon } from "lucide-react";

type Props = ComponentPropsWithoutRef<"div"> & {
  currency: FiatCurrencyI;
  balance: number;
  type?: "normal" | "button";
  className?: string;
};
type AddProps = ComponentPropsWithoutRef<"div"> & {
  type?: "normal" | "button";
  className?: string;
};

const cardVariants = cva("transition-all duration-200 ", {
  variants: {
    type: {
      normal: "",
      button:
        "ring-primary cursor-pointer ring-0 data-[active=true]:ring-2 data-[active=true]:ring-offset-2",
    },
  },
  defaultVariants: {
    type: "normal",
  },
});

export function SubwalletCard(props: Props) {
  const { currency, balance, type, className, ...PROPS } = props;

  return (
    <CardRoot
      {...PROPS}
      className={cardVariants({ type, className })}
      style={{
        "--tw-ring-offset-color": "var(--wallet-card-offset-color, whitesmoke)",
      }}
    >
      <CardBody className={"flex items-center justify-between py-4"}>
        <div className={"mt-2"}>
          <div className={"flex items-center gap-x-2"}>
            <img
              src={currency?.flagUrl}
              className="h-[27px] w-[30px]"
              alt={currency?.flagName}
            />
            <span className={"font-medium text-neutral-500"}>
              {currency.label}
            </span>
          </div>
          <p className={"py-3 text-[14px] font-normal text-neutral-500"}>
            {currency?.name}
          </p>
        </div>

        <p className={"text-[20px] font-semibold text-neutral-500"}>
          {currency.symbol} {formatNumber(String(balance), { decimal: true })}
        </p>
      </CardBody>
    </CardRoot>
  );
}

export function WalletCard(props: Props) {
  const { currency, balance, type, className, ...PROPS } = props;

  return (
    <CardRoot
      {...PROPS}
      className={cardVariants({ type, className })}
      style={{
        "--tw-ring-offset-color": "var(--wallet-card-offset-color, whitesmoke)",
      }}
    >
      <CardBody className={"flex items-center justify-between py-4"}>
        <div className={"mt-2"}>
          <div className={"flex items-center gap-x-2"}>
            <img
              src={currency?.flagUrl}
              className="h-[27px] w-[30px]"
              alt={currency?.flagName}
            />
            <span className={"font-semibold"}>{currency.label}</span>
          </div>
          <p className={"py-3 text-[14px] font-normal text-neutral-500"}>
            {currency?.name}
          </p>
        </div>

        <p className={"text-[20px] font-semibold text-neutral-500"}>
          {currency.symbol} {formatNumber(String(balance), { decimal: true })}
        </p>
      </CardBody>
    </CardRoot>
  );
}

export function AddWalletCard(props: AddProps) {
  const { type, className, ...PROPS } = props;

  return (
    <CardRoot {...PROPS} className={cardVariants({ type, className })}>
      <CardBody
        className={
          "flex h-full items-center justify-center bg-[white]/[10%] py-4"
        }
      >
        <div className={"flex flex-col items-center justify-center gap-x-2"}>
          <PlusIcon
            absoluteStrokeWidth={false}
            size={"1.5rem"}
            stroke={"#9BA8B5"}
            strokeWidth={1.8}
          />
          <span className={"text-xs font-light text-[#9BA8B5]"}>
            Add Wallet
          </span>
        </div>
      </CardBody>
    </CardRoot>
  );
}
