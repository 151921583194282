/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useLiquidityContext, LiquidityContext } from "./liquidityContext";
import type * as Fetcher from "./liquidityFetcher";
import { liquidityFetch } from "./liquidityFetcher";

export type GetTransactionMetricsPathParams = {
  businessId: string;
};

export type GetTransactionMetricsError = Fetcher.ErrorWrapper<undefined>;

export type GetTransactionMetricsVariables = {
  pathParams: GetTransactionMetricsPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetTransactionMetrics = (
  variables: GetTransactionMetricsVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetTransactionMetricsError,
    undefined,
    {},
    {},
    GetTransactionMetricsPathParams
  >({
    url: "/v1/business/{businessId}/metrics/transactions",
    method: "get",
    ...variables,
    signal,
  });

export const useGetTransactionMetrics = <TData = Record<string, any>>(
  variables: GetTransactionMetricsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetTransactionMetricsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetTransactionMetricsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/metrics/transactions",
      operationId: "getTransactionMetrics",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTransactionMetrics({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetEntitiesBalancesPathParams = {
  businessId: string;
};

export type GetEntitiesBalancesQueryParams = {
  limit?: number;
  page?: number;
};

export type GetEntitiesBalancesError = Fetcher.ErrorWrapper<undefined>;

export type GetEntitiesBalancesVariables = {
  pathParams: GetEntitiesBalancesPathParams;
  queryParams?: GetEntitiesBalancesQueryParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetEntitiesBalances = (
  variables: GetEntitiesBalancesVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetEntitiesBalancesError,
    undefined,
    {},
    GetEntitiesBalancesQueryParams,
    GetEntitiesBalancesPathParams
  >({
    url: "/v1/business/{businessId}/metrics/balances",
    method: "get",
    ...variables,
    signal,
  });

export const useGetEntitiesBalances = <TData = Record<string, any>>(
  variables: GetEntitiesBalancesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetEntitiesBalancesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetEntitiesBalancesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/metrics/balances",
      operationId: "getEntitiesBalances",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetEntitiesBalances({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type P2PPathParams = {
  business: string;
  walletId: string;
};

export type P2PError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type P2PVariables = {
  body?: Record<string, any>;
  pathParams: P2PPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchP2P = (variables: P2PVariables, signal?: AbortSignal) =>
  liquidityFetch<
    Record<string, any>,
    P2PError,
    Record<string, any>,
    {},
    {},
    P2PPathParams
  >({
    url: "/v1/business/{business}/wallet/{walletId}/payment/initiate-payment",
    method: "post",
    ...variables,
    signal,
  });

export const useP2P = (
  options?: Omit<
    reactQuery.UseMutationOptions<Record<string, any>, P2PError, P2PVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<Record<string, any>, P2PError, P2PVariables>({
    mutationFn: (variables: P2PVariables) =>
      fetchP2P({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLinkedAccountTransactionHistoryPathParams = {
  businessId: string;
  linkedAccountId: string;
};

export type GetLinkedAccountTransactionHistoryQueryParams = {
  limit?: number;
  page?: number;
  flowType?: string;
};

export type GetLinkedAccountTransactionHistoryError =
  Fetcher.ErrorWrapper<undefined>;

export type GetLinkedAccountTransactionHistoryVariables = {
  pathParams: GetLinkedAccountTransactionHistoryPathParams;
  queryParams?: GetLinkedAccountTransactionHistoryQueryParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetLinkedAccountTransactionHistory = (
  variables: GetLinkedAccountTransactionHistoryVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetLinkedAccountTransactionHistoryError,
    undefined,
    {},
    GetLinkedAccountTransactionHistoryQueryParams,
    GetLinkedAccountTransactionHistoryPathParams
  >({
    url: "/v1/business/{businessId}/linked-account/{linkedAccountId}/transactions",
    method: "get",
    ...variables,
    signal,
  });

export const useGetLinkedAccountTransactionHistory = <
  TData = Record<string, any>
>(
  variables: GetLinkedAccountTransactionHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetLinkedAccountTransactionHistoryError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetLinkedAccountTransactionHistoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/linked-account/{linkedAccountId}/transactions",
      operationId: "getLinkedAccountTransactionHistory",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLinkedAccountTransactionHistory(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetWalletTransactionHistoriesPathParams = {
  businessId: string;
  walletId: string;
};

export type GetWalletTransactionHistoriesQueryParams = {
  limit?: number;
  page?: number;
  flowType?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  amount?: string;
  currency?: string;
};

export type GetWalletTransactionHistoriesError =
  Fetcher.ErrorWrapper<undefined>;

export type GetWalletTransactionHistoriesVariables = {
  pathParams: GetWalletTransactionHistoriesPathParams;
  queryParams?: GetWalletTransactionHistoriesQueryParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetWalletTransactionHistories = (
  variables: GetWalletTransactionHistoriesVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetWalletTransactionHistoriesError,
    undefined,
    {},
    GetWalletTransactionHistoriesQueryParams,
    GetWalletTransactionHistoriesPathParams
  >({
    url: "/v1/business/{businessId}/wallet/{walletId}/transactions",
    method: "get",
    ...variables,
    signal,
  });

export const useGetWalletTransactionHistories = <TData = Record<string, any>>(
  variables: GetWalletTransactionHistoriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetWalletTransactionHistoriesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetWalletTransactionHistoriesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/wallet/{walletId}/transactions",
      operationId: "getWalletTransactionHistories",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWalletTransactionHistories(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetAllAssociatedWalletsTransactionsPathParams = {
  businessId: string;
};

export type GetAllAssociatedWalletsTransactionsQueryParams = {
  page?: number;
  limit?: number;
  wallets?: string;
  startDate?: string;
  endDate?: string;
  flowType?: string;
  /**
   * funding or FUNDING (value is not case sensitive)
   */
  status?: string;
  /**
   * pending or PENDING (value is not case sensitive)
   */
  amount?: string;
  /**
   * can be <100 or >100 or =100
   */
  currency?: string;
};

export type GetAllAssociatedWalletsTransactionsError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAllAssociatedWalletsTransactionsVariables = {
  pathParams: GetAllAssociatedWalletsTransactionsPathParams;
  queryParams?: GetAllAssociatedWalletsTransactionsQueryParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetAllAssociatedWalletsTransactions = (
  variables: GetAllAssociatedWalletsTransactionsVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetAllAssociatedWalletsTransactionsError,
    undefined,
    {},
    GetAllAssociatedWalletsTransactionsQueryParams,
    GetAllAssociatedWalletsTransactionsPathParams
  >({
    url: "/v1/business/{businessId}/wallet/transactions",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAllAssociatedWalletsTransactions = <
  TData = Record<string, any>
>(
  variables: GetAllAssociatedWalletsTransactionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetAllAssociatedWalletsTransactionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetAllAssociatedWalletsTransactionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/wallet/transactions",
      operationId: "getAllAssociatedWalletsTransactions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllAssociatedWalletsTransactions(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetBusinessWalletsPathParams = {
  businessId: string;
};

export type GetBusinessWalletsError = Fetcher.ErrorWrapper<undefined>;

export type GetBusinessWalletsVariables = {
  pathParams: GetBusinessWalletsPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetBusinessWallets = (
  variables: GetBusinessWalletsVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetBusinessWalletsError,
    undefined,
    {},
    {},
    GetBusinessWalletsPathParams
  >({
    url: "/v1/business/{businessId}/wallet",
    method: "get",
    ...variables,
    signal,
  });

export const useGetBusinessWallets = <TData = Record<string, any>>(
  variables: GetBusinessWalletsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetBusinessWalletsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetBusinessWalletsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/wallet",
      operationId: "getBusinessWallets",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetBusinessWallets({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAnEntityMultiCurrencyWalletsUsdNgnPathParams = {
  businessId: string;
  entityId: string;
};

export type GetAnEntityMultiCurrencyWalletsUsdNgnError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAnEntityMultiCurrencyWalletsUsdNgnVariables = {
  pathParams: GetAnEntityMultiCurrencyWalletsUsdNgnPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetAnEntityMultiCurrencyWalletsUsdNgn = (
  variables: GetAnEntityMultiCurrencyWalletsUsdNgnVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetAnEntityMultiCurrencyWalletsUsdNgnError,
    undefined,
    {},
    {},
    GetAnEntityMultiCurrencyWalletsUsdNgnPathParams
  >({
    url: "/v1/business/{businessId}/wallet/multi-currency/{entityId}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAnEntityMultiCurrencyWalletsUsdNgn = <
  TData = Record<string, any>
>(
  variables: GetAnEntityMultiCurrencyWalletsUsdNgnVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetAnEntityMultiCurrencyWalletsUsdNgnError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetAnEntityMultiCurrencyWalletsUsdNgnError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/wallet/multi-currency/{entityId}",
      operationId: "getAnEntityMultiCurrencyWalletsUsdNgn",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAnEntityMultiCurrencyWalletsUsdNgn(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type CreateBusinessWalletPathParams = {
  businessId: string;
};

export type CreateBusinessWalletError = Fetcher.ErrorWrapper<undefined>;

export type CreateBusinessWalletVariables = {
  body?: Record<string, any>;
  pathParams: CreateBusinessWalletPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchCreateBusinessWallet = (
  variables: CreateBusinessWalletVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    CreateBusinessWalletError,
    Record<string, any>,
    {},
    {},
    CreateBusinessWalletPathParams
  >({
    url: "/v1/business/{businessId}/wallet/create",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateBusinessWallet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateBusinessWalletError,
      CreateBusinessWalletVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateBusinessWalletError,
    CreateBusinessWalletVariables
  >({
    mutationFn: (variables: CreateBusinessWalletVariables) =>
      fetchCreateBusinessWallet({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableBusinessWalletPathParams = {
  businessId: string;
};

export type DisableBusinessWalletError = Fetcher.ErrorWrapper<undefined>;

export type DisableBusinessWalletVariables = {
  body?: Record<string, any>;
  pathParams: DisableBusinessWalletPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchDisableBusinessWallet = (
  variables: DisableBusinessWalletVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    DisableBusinessWalletError,
    Record<string, any>,
    {},
    {},
    DisableBusinessWalletPathParams
  >({
    url: "/v1/business/{businessId}/wallet/disable",
    method: "put",
    ...variables,
    signal,
  });

export const useDisableBusinessWallet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      DisableBusinessWalletError,
      DisableBusinessWalletVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    DisableBusinessWalletError,
    DisableBusinessWalletVariables
  >({
    mutationFn: (variables: DisableBusinessWalletVariables) =>
      fetchDisableBusinessWallet({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCheckoutOptionsPathParams = {
  token: string;
  country: string;
};

export type GetCheckoutOptionsError = Fetcher.ErrorWrapper<undefined>;

export type GetCheckoutOptionsVariables = {
  pathParams: GetCheckoutOptionsPathParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchGetCheckoutOptions = (
  variables: GetCheckoutOptionsVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetCheckoutOptionsError,
    undefined,
    {},
    {},
    GetCheckoutOptionsPathParams
  >({
    url: "/v1/application-token/{token}/checkout/{country}/options",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useGetCheckoutOptions = <TData = Record<string, any>>(
  variables: GetCheckoutOptionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetCheckoutOptionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetCheckoutOptionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/application-token/{token}/checkout/{country}/options",
      operationId: "getCheckoutOptions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCheckoutOptions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OptionsMetadataPathParams = {
  token: string;
  currency: string;
};

export type OptionsMetadataError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type OptionsMetadataVariables = {
  pathParams: OptionsMetadataPathParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchOptionsMetadata = (
  variables: OptionsMetadataVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    OptionsMetadataError,
    undefined,
    {},
    {},
    OptionsMetadataPathParams
  >({
    url: "/v1/application-token/{token}/linked-accounts/{currency}/options",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useOptionsMetadata = <TData = Record<string, any>>(
  variables: OptionsMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      OptionsMetadataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<Record<string, any>, OptionsMetadataError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/application-token/{token}/linked-accounts/{currency}/options",
      operationId: "optionsMetadata",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOptionsMetadata({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AddLinkedAccountBankNgnPathParams = {
  token: string;
  currency: string;
  option: string;
  step: number;
};

export type AddLinkedAccountBankNgnError = Fetcher.ErrorWrapper<undefined>;

export type AddLinkedAccountBankNgnVariables = {
  body?: Record<string, any>;
  pathParams: AddLinkedAccountBankNgnPathParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchAddLinkedAccountBankNgn = (
  variables: AddLinkedAccountBankNgnVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    AddLinkedAccountBankNgnError,
    Record<string, any>,
    {},
    {},
    AddLinkedAccountBankNgnPathParams
  >({
    url: "/v1/application-token/{token}/linked-accounts/{currency}/link/{option}/{step}",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useAddLinkedAccountBankNgn = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      AddLinkedAccountBankNgnError,
      AddLinkedAccountBankNgnVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    AddLinkedAccountBankNgnError,
    AddLinkedAccountBankNgnVariables
  >({
    mutationFn: (variables: AddLinkedAccountBankNgnVariables) =>
      fetchAddLinkedAccountBankNgn({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MakeTransactionPathParams = {
  token: string;
};

export type MakeTransactionError = Fetcher.ErrorWrapper<undefined>;

export type MakeTransactionVariables = {
  body?: Record<string, any>;
  pathParams: MakeTransactionPathParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchMakeTransaction = (
  variables: MakeTransactionVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    MakeTransactionError,
    Record<string, any>,
    {},
    {},
    MakeTransactionPathParams
  >({
    url: "/v1/application-token/{token}/transaction",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useMakeTransaction = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      MakeTransactionError,
      MakeTransactionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    MakeTransactionError,
    MakeTransactionVariables
  >({
    mutationFn: (variables: MakeTransactionVariables) =>
      fetchMakeTransaction({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTokensPathParams = {
  businessId: string;
};

export type GetTokensQueryParams = {
  limit?: number;
  page?: number;
  type?: string;
};

export type GetTokensError = Fetcher.ErrorWrapper<undefined>;

export type GetTokensVariables = {
  pathParams: GetTokensPathParams;
  queryParams?: GetTokensQueryParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchGetTokens = (
  variables: GetTokensVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetTokensError,
    undefined,
    {},
    GetTokensQueryParams,
    GetTokensPathParams
  >({
    url: "/v1/business/{businessId}/application-token",
    method: "get",
    ...variables,
    signal,
  });

export const fetchGetToken = (
  variables: { tokenId: string },
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetTokensError,
    undefined,
    {},
    {},
    GetTokensPathParams
  >({
    url: `/v1/application-token/${variables.tokenId}`,
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useGetTokens = <TData = Record<string, any>>(
  variables: GetTokensVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Record<string, any>, GetTokensError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<Record<string, any>, GetTokensError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/application-token",
      operationId: "getTokens",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTokens({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export const useGetToken = <TData = Record<string, any>>(
  tokenId: string,
  options?: Omit<
    reactQuery.UseQueryOptions<Record<string, any>, GetTokensError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  console.log("tokenn", tokenId);
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);

  return reactQuery.useQuery<Record<string, any>, GetTokensError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/application-token/{tokenId}",
      operationId: "getToken",
      variables: { tokenId }, // Pass tokenId as a variable
    }),
    queryFn: ({ signal }) =>
      fetchGetToken({ ...fetcherOptions, tokenId }, signal), // Fetch specific token
    ...options,
    ...queryOptions,
  });
};

export type CreateApplicationTokenAccountPathParams = {
  businessId: string;
};

export type CreateApplicationTokenAccountError =
  Fetcher.ErrorWrapper<undefined>;

export type CreateApplicationTokenAccountVariables = {
  body?: Record<string, any>;
  pathParams: CreateApplicationTokenAccountPathParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchCreateApplicationTokenAccount = (
  variables: CreateApplicationTokenAccountVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    CreateApplicationTokenAccountError,
    Record<string, any>,
    {},
    {},
    CreateApplicationTokenAccountPathParams
  >({
    url: "/v1/business/{businessId}/application-token",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useCreateApplicationTokenAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateApplicationTokenAccountError,
      CreateApplicationTokenAccountVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateApplicationTokenAccountError,
    CreateApplicationTokenAccountVariables
  >({
    mutationFn: (variables: CreateApplicationTokenAccountVariables) =>
      fetchCreateApplicationTokenAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetApplicationTokenMetadataPathParams = {
  token: string;
};

export type GetApplicationTokenMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetApplicationTokenMetadataVariables = {
  pathParams: GetApplicationTokenMetadataPathParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchGetApplicationTokenMetadata = (
  variables: GetApplicationTokenMetadataVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetApplicationTokenMetadataError,
    undefined,
    {},
    {},
    GetApplicationTokenMetadataPathParams
  >({
    url: "/v1/application-token/{token}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useGetApplicationTokenMetadata = <TData = Record<string, any>>(
  variables: GetApplicationTokenMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetApplicationTokenMetadataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetApplicationTokenMetadataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/application-token/{token}",
      operationId: "getApplicationTokenMetadata",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetApplicationTokenMetadata(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type EditApplicationTokenPathParams = {
  businessId: string;
  token: string;
};

export type EditApplicationTokenError = Fetcher.ErrorWrapper<undefined>;

export type EditApplicationTokenVariables = {
  body?: Record<string, any>;
  pathParams: EditApplicationTokenPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchEditApplicationToken = (
  variables: EditApplicationTokenVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    EditApplicationTokenError,
    Record<string, any>,
    {},
    {},
    EditApplicationTokenPathParams
  >({
    url: "/v1/business/{businessId}/application-token/{token}",
    method: "put",
    ...variables,
    signal,
  });

export const useEditApplicationToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      EditApplicationTokenError,
      EditApplicationTokenVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    EditApplicationTokenError,
    EditApplicationTokenVariables
  >({
    mutationFn: (variables: EditApplicationTokenVariables) =>
      fetchEditApplicationToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type StoreTransactionCheckbookPathParams = {
  integration: string;
};

export type StoreTransactionCheckbookError = Fetcher.ErrorWrapper<undefined>;

export type StoreTransactionCheckbookVariables = {
  body?: Record<string, any>;
  pathParams: StoreTransactionCheckbookPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchStoreTransactionCheckbook = (
  variables: StoreTransactionCheckbookVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    StoreTransactionCheckbookError,
    Record<string, any>,
    {},
    {},
    StoreTransactionCheckbookPathParams
  >({
    url: "/v1/webhook/banks/{integration}",
    method: "post",
    ...variables,
    signal,
  });

export const useStoreTransactionCheckbook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      StoreTransactionCheckbookError,
      StoreTransactionCheckbookVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    StoreTransactionCheckbookError,
    StoreTransactionCheckbookVariables
  >({
    mutationFn: (variables: StoreTransactionCheckbookVariables) =>
      fetchStoreTransactionCheckbook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PayInPathParams = {
  businessId: string;
};

export type PayInError = Fetcher.ErrorWrapper<undefined>;

export type PayInVariables = {
  body?: Record<string, any>;
  pathParams: PayInPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchPayIn = (variables: PayInVariables, signal?: AbortSignal) =>
  liquidityFetch<
    Record<string, any>,
    PayInError,
    Record<string, any>,
    {},
    {},
    PayInPathParams
  >({
    url: "/v1/business/{businessId}/banks/mock/pay-in",
    method: "post",
    ...variables,
    signal,
  });

export const usePayIn = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      PayInError,
      PayInVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    PayInError,
    PayInVariables
  >({
    mutationFn: (variables: PayInVariables) =>
      fetchPayIn({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RetrieveVirtualCollectionAccountPathParams = {
  businessId: string;
  walletId: string;
};

export type RetrieveVirtualCollectionAccountError =
  Fetcher.ErrorWrapper<undefined>;

export type RetrieveVirtualCollectionAccountVariables = {
  pathParams: RetrieveVirtualCollectionAccountPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchRetrieveVirtualCollectionAccount = (
  variables: RetrieveVirtualCollectionAccountVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    RetrieveVirtualCollectionAccountError,
    undefined,
    {},
    {},
    RetrieveVirtualCollectionAccountPathParams
  >({
    url: "/v1/business/{businessId}/banks/collection-account/retrieve/{walletId}",
    method: "get",
    ...variables,
    signal,
  });

export const useRetrieveVirtualCollectionAccount = <
  TData = Record<string, any>
>(
  variables: RetrieveVirtualCollectionAccountVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      RetrieveVirtualCollectionAccountError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    RetrieveVirtualCollectionAccountError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/banks/collection-account/retrieve/{walletId}",
      operationId: "retrieveVirtualCollectionAccount",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRetrieveVirtualCollectionAccount(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type CreateVirtualCollectionAccountPathParams = {
  businessId: string;
  walletId: string;
};

export type CreateVirtualCollectionAccountError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type CreateVirtualCollectionAccountVariables = {
  body?: Record<string, any>;
  pathParams: CreateVirtualCollectionAccountPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchCreateVirtualCollectionAccount = (
  variables: CreateVirtualCollectionAccountVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    CreateVirtualCollectionAccountError,
    Record<string, any>,
    {},
    {},
    CreateVirtualCollectionAccountPathParams
  >({
    url: "/v1/business/{businessId}/banks/collection-account/create/{walletId}",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateVirtualCollectionAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateVirtualCollectionAccountError,
      CreateVirtualCollectionAccountVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateVirtualCollectionAccountError,
    CreateVirtualCollectionAccountVariables
  >({
    mutationFn: (variables: CreateVirtualCollectionAccountVariables) =>
      fetchCreateVirtualCollectionAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSupportedBanksError = Fetcher.ErrorWrapper<undefined>;

export type GetSupportedBanksVariables = LiquidityContext["fetcherOptions"];

export const fetchGetSupportedBanks = (
  variables: GetSupportedBanksVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetSupportedBanksError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/v1/metadata/banks/supported-banks",
    method: "get",
    ...variables,
    signal,
  });

export const useGetSupportedBanks = <TData = Record<string, any>>(
  variables: GetSupportedBanksVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetSupportedBanksError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetSupportedBanksError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/metadata/banks/supported-banks",
      operationId: "getSupportedBanks",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSupportedBanks({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type WithdrawalPathParams = {
  businessId: string;
  currency: string;
  linkedAccountId: string;
};

export type WithdrawalError = Fetcher.ErrorWrapper<undefined>;

export type WithdrawalVariables = {
  body?: Record<string, any>;
  pathParams: WithdrawalPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchWithdrawal = (
  variables: WithdrawalVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    WithdrawalError,
    Record<string, any>,
    {},
    {},
    WithdrawalPathParams
  >({
    url: "/v1/business/{businessId}/linked-accounts/{currency}/{linkedAccountId}/withdrawal",
    method: "post",
    ...variables,
    signal,
  });

export const useWithdrawal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      WithdrawalError,
      WithdrawalVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    WithdrawalError,
    WithdrawalVariables
  >({
    mutationFn: (variables: WithdrawalVariables) =>
      fetchWithdrawal({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreatePaymentPageError = Fetcher.ErrorWrapper<undefined>;

export type CreatePaymentPageVariables = {
  body?: Record<string, any>;
} & LiquidityContext["fetcherOptions"];

export const fetchCreatePaymentPage = (
  variables: CreatePaymentPageVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    undefined,
    CreatePaymentPageError,
    Record<string, any>,
    {},
    {},
    {}
  >({ url: "/v1/wallet/payment-link", method: "post", ...variables, signal });

export const useCreatePaymentPage = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CreatePaymentPageError,
      CreatePaymentPageVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    undefined,
    CreatePaymentPageError,
    CreatePaymentPageVariables
  >({
    mutationFn: (variables: CreatePaymentPageVariables) =>
      fetchCreatePaymentPage({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateIavTokenPathParams = {
  businessId: string;
  walletId: string;
};

export type CreateIavTokenError = Fetcher.ErrorWrapper<undefined>;

export type CreateIavTokenVariables = {
  pathParams: CreateIavTokenPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchCreateIavToken = (
  variables: CreateIavTokenVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    CreateIavTokenError,
    undefined,
    {},
    {},
    CreateIavTokenPathParams
  >({
    url: "/v1/business/{businessId}/wallet/{walletId}/kyc/iav",
    method: "get",
    ...variables,
    signal,
  });

export const useCreateIavToken = <TData = Record<string, any>>(
  variables: CreateIavTokenVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Record<string, any>, CreateIavTokenError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<Record<string, any>, CreateIavTokenError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/wallet/{walletId}/kyc/iav",
      operationId: "createIavToken",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchCreateIavToken({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ConsumeIavTokenPathParams = {
  businessId: string;
  walletId: string;
};

export type ConsumeIavTokenError = Fetcher.ErrorWrapper<undefined>;

export type ConsumeIavTokenVariables = {
  body?: Record<string, any>;
  pathParams: ConsumeIavTokenPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchConsumeIavToken = (
  variables: ConsumeIavTokenVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    ConsumeIavTokenError,
    Record<string, any>,
    {},
    {},
    ConsumeIavTokenPathParams
  >({
    url: "/v1/business/{businessId}/wallet/{walletId}/kyc/iav",
    method: "post",
    ...variables,
    signal,
  });

export const useConsumeIavToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ConsumeIavTokenError,
      ConsumeIavTokenVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ConsumeIavTokenError,
    ConsumeIavTokenVariables
  >({
    mutationFn: (variables: ConsumeIavTokenVariables) =>
      fetchConsumeIavToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateBusinessKycNgnPathParams = {
  businessId: string;
  tier: number;
};

export type UpdateBusinessKycNgnError = Fetcher.ErrorWrapper<undefined>;

export type UpdateBusinessKycNgnVariables = {
  body?: Record<string, any>;
  pathParams: UpdateBusinessKycNgnPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchUpdateBusinessKycNgn = (
  variables: UpdateBusinessKycNgnVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    UpdateBusinessKycNgnError,
    Record<string, any>,
    {},
    {},
    UpdateBusinessKycNgnPathParams
  >({
    url: "/v1/business/{businessId}/update-data-collection/{tier}",
    method: "post",
    ...variables,
    signal,
  });

export const useUpdateBusinessKycNgn = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      UpdateBusinessKycNgnError,
      UpdateBusinessKycNgnVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    UpdateBusinessKycNgnError,
    UpdateBusinessKycNgnVariables
  >({
    mutationFn: (variables: UpdateBusinessKycNgnVariables) =>
      fetchUpdateBusinessKycNgn({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetRatesPathParams = {
  businessId: string;
  currency: string;
};

export type GetRatesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type GetRatesVariables = {
  pathParams: GetRatesPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetRates = (
  variables: GetRatesVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetRatesError,
    undefined,
    {},
    {},
    GetRatesPathParams
  >({
    url: "/v1/business/{businessId}/exchange/{currency}/rates",
    method: "get",
    ...variables,
    signal,
  });

export const useGetRates = <TData = Record<string, any>>(
  variables: GetRatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Record<string, any>, GetRatesError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<Record<string, any>, GetRatesError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/exchange/{currency}/rates",
      operationId: "getRates",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetRates({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type InitiateExchangePathParams = {
  businessId: string;
  currency: string;
};

export type InitiateExchangeError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type InitiateExchangeVariables = {
  body?: Record<string, any>;
  pathParams: InitiateExchangePathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchInitiateExchange = (
  variables: InitiateExchangeVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    InitiateExchangeError,
    Record<string, any>,
    {},
    {},
    InitiateExchangePathParams
  >({
    url: "/v1/business/{businessId}/exchange/{currency}/initialize",
    method: "post",
    ...variables,
    signal,
  });

export const useInitiateExchange = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      InitiateExchangeError,
      InitiateExchangeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLiquidityContext();
  return reactQuery.useMutation<
    Record<string, any>,
    InitiateExchangeError,
    InitiateExchangeVariables
  >({
    mutationFn: (variables: InitiateExchangeVariables) =>
      fetchInitiateExchange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBusinessAuditLogPathParams = {
  businessId: string;
};

export type GetBusinessAuditLogError = Fetcher.ErrorWrapper<undefined>;

export type GetBusinessAuditLogVariables = {
  pathParams: GetBusinessAuditLogPathParams;
} & LiquidityContext["fetcherOptions"];

export const fetchGetBusinessAuditLog = (
  variables: GetBusinessAuditLogVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetBusinessAuditLogError,
    undefined,
    {},
    {},
    GetBusinessAuditLogPathParams
  >({
    url: "/v1/business/{businessId}/audit-logs",
    method: "get",
    ...variables,
    signal,
  });

export const useGetBusinessAuditLog = <TData = Record<string, any>>(
  variables: GetBusinessAuditLogVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetBusinessAuditLogError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetBusinessAuditLogError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/audit-logs",
      operationId: "getBusinessAuditLog",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetBusinessAuditLog({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetLinkedAccountsPathParams = {
  businessId: string;
  currency: string;
};

export type GetLinkedAccountsQueryParams = {
  limit?: number;
  page?: number;
  externalId?: string;
};

export type GetLinkedAccountsError = Fetcher.ErrorWrapper<undefined>;

export type GetLinkedAccountsVariables = {
  pathParams: GetLinkedAccountsPathParams;
  queryParams?: GetLinkedAccountsQueryParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchGetLinkedAccounts = (
  variables: GetLinkedAccountsVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetLinkedAccountsError,
    undefined,
    {},
    GetLinkedAccountsQueryParams,
    GetLinkedAccountsPathParams
  >({
    url: "/v1/business/{businessId}/linked-accounts/{currency}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useGetLinkedAccounts = <TData = Record<string, any>>(
  variables: GetLinkedAccountsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetLinkedAccountsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetLinkedAccountsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/linked-accounts/{currency}",
      operationId: "getLinkedAccounts",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLinkedAccounts({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetLinkedAccountsByCurrenciesPathParams = {
  businessId: string;
};

export type GetLinkedAccountsByCurrenciesQueryParams = {
  limit?: number;
  page?: number;
  currencies?: string;
  optionType?: string;
  search?: string;
};

export type GetLinkedAccountsByCurrenciesError =
  Fetcher.ErrorWrapper<undefined>;

export type GetLinkedAccountsByCurrenciesVariables = {
  pathParams: GetLinkedAccountsByCurrenciesPathParams;
  queryParams?: GetLinkedAccountsByCurrenciesQueryParams;
} & LiquidityContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchGetLinkedAccountsByCurrencies = (
  variables: GetLinkedAccountsByCurrenciesVariables,
  signal?: AbortSignal
) =>
  liquidityFetch<
    Record<string, any>,
    GetLinkedAccountsByCurrenciesError,
    undefined,
    {},
    GetLinkedAccountsByCurrenciesQueryParams,
    GetLinkedAccountsByCurrenciesPathParams
  >({
    url: "/v1/business/{businessId}/linked-accounts",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useGetLinkedAccountsByCurrencies = <TData = Record<string, any>>(
  variables: GetLinkedAccountsByCurrenciesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetLinkedAccountsByCurrenciesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useLiquidityContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetLinkedAccountsByCurrenciesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/linked-accounts",
      operationId: "getLinkedAccountsByCurrencies",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLinkedAccountsByCurrencies(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: "/v1/business/{businessId}/metrics/transactions";
      operationId: "getTransactionMetrics";
      variables: GetTransactionMetricsVariables;
    }
  | {
      path: "/v1/business/{businessId}/metrics/balances";
      operationId: "getEntitiesBalances";
      variables: GetEntitiesBalancesVariables;
    }
  | {
      path: "/v1/business/{businessId}/linked-account/{linkedAccountId}/transactions";
      operationId: "getLinkedAccountTransactionHistory";
      variables: GetLinkedAccountTransactionHistoryVariables;
    }
  | {
      path: "/v1/business/{businessId}/wallet/{walletId}/transactions";
      operationId: "getWalletTransactionHistories";
      variables: GetWalletTransactionHistoriesVariables;
    }
  | {
      path: "/v1/business/{businessId}/wallet/transactions";
      operationId: "getAllAssociatedWalletsTransactions";
      variables: GetAllAssociatedWalletsTransactionsVariables;
    }
  | {
      path: "/v1/business/{businessId}/wallet";
      operationId: "getBusinessWallets";
      variables: GetBusinessWalletsVariables;
    }
  | {
      path: "/v1/business/{businessId}/wallet/multi-currency/{entityId}";
      operationId: "getAnEntityMultiCurrencyWalletsUsdNgn";
      variables: GetAnEntityMultiCurrencyWalletsUsdNgnVariables;
    }
  | {
      path: "/v1/application-token/{token}/checkout/{country}/options";
      operationId: "getCheckoutOptions";
      variables: GetCheckoutOptionsVariables;
    }
  | {
      path: "/v1/application-token/{token}/linked-accounts/{currency}/options";
      operationId: "optionsMetadata";
      variables: OptionsMetadataVariables;
    }
  | {
      path: "/v1/business/{businessId}/application-token";
      operationId: "getTokens";
      variables: GetTokensVariables;
    }
  | {
      path: "/v1/application-token/{token}";
      operationId: "getApplicationTokenMetadata";
      variables: GetApplicationTokenMetadataVariables;
    }
  | {
      path: "/v1/business/{businessId}/banks/collection-account/retrieve/{walletId}";
      operationId: "retrieveVirtualCollectionAccount";
      variables: RetrieveVirtualCollectionAccountVariables;
    }
  | {
      path: "/v1/metadata/banks/supported-banks";
      operationId: "getSupportedBanks";
      variables: GetSupportedBanksVariables;
    }
  | {
      path: "/v1/business/{businessId}/wallet/{walletId}/kyc/iav";
      operationId: "createIavToken";
      variables: CreateIavTokenVariables;
    }
  | {
      path: "/v1/business/{businessId}/exchange/{currency}/rates";
      operationId: "getRates";
      variables: GetRatesVariables;
    }
  | {
      path: "/v1/business/{businessId}/audit-logs";
      operationId: "getBusinessAuditLog";
      variables: GetBusinessAuditLogVariables;
    }
  | {
      path: "/v1/business/{businessId}/linked-accounts/{currency}";
      operationId: "getLinkedAccounts";
      variables: GetLinkedAccountsVariables;
    }
  | {
      path: "/v1/business/{businessId}/linked-accounts";
      operationId: "getLinkedAccountsByCurrencies";
      variables: GetLinkedAccountsByCurrenciesVariables;
    }
  | {
      path: `/v1/application-token/{tokenId}`;
      operationId: "getToken";
      variables: { tokenId: string };
    };
