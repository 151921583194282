import React from "react";
import { CaretDown } from "~/assets/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { SectionHeading } from "~/components/atoms/heading";
import { CurrencyDropdown } from "../Inputs";
import { CountryI } from "~/general/interfaces";
import { Input } from "~/@/components/ui/input";
import { InputFormatNumber } from "~/libs/currency.helpers";
import { Button } from "~/@/components/ui/button";
import DatePicker from "../DatePicker";
import { useCountries } from "~/hooks";

export interface FilterTypes {
  position?: string;
  isHistory?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showFilter?: boolean;
  setShowFilter?: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilter?: () => void;
  handleFilter?: () => void;
  transactionMethod?: string;
  setTransactionMethod?: React.Dispatch<React.SetStateAction<string>>;
  transactionStatus?: string;
  setTransactionStatus?: React.Dispatch<React.SetStateAction<string>>;
  amount?: string;
  setAmount?: React.Dispatch<React.SetStateAction<string>>;
  amountRange?: string;
  setAmountRange?: React.Dispatch<React.SetStateAction<string>>;
  currency?: CountryI;
  setCurrency?: React.Dispatch<React.SetStateAction<CountryI>>;
  handleCurrencyChange?: (val: CountryI) => void;
  fromDate?: string | null;
  setFromDate?: React.Dispatch<React.SetStateAction<string | null>>;
  toDate?: string | null;
  setToDate?: React.Dispatch<React.SetStateAction<string | null>>;
  currentTab?: undefined | string;
  downloadCSV?: () => void;
}

function TransactionsFilters({
  position = "start",
  isHistory = false,
  open,
  setOpen,
  showFilter,
  setShowFilter,
  clearFilter,
  handleFilter,
  transactionMethod,
  setTransactionMethod,
  transactionStatus,
  setTransactionStatus,
  amount,
  setAmount,
  amountRange,
  setAmountRange,
  currency,
  setCurrency,
  handleCurrencyChange,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  currentTab,
  downloadCSV,
}: FilterTypes) {
  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    // setAmount(InputFormatNumber(numericValue));
    setAmount(numericValue);
  };

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(state) => {
        setOpen(state);
      }}
    >
      <DropdownMenuTrigger className="">
        <div
          className={`flex cursor-pointer items-center text-sm ${
            !isHistory ? "text-gray-800" : "text-[#909090]"
          }`}
        >
          {!showFilter && "Filters"}
          <div className="ml-[5px]">
            <CaretDown color={!isHistory ? "#303940" : "#909090"} />
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={10}
        align={"start"}
        className="mr-2 min-w-[350px] max-w-[350px] overflow-x-auto rounded px-5"
      >
        <div className="pt-2">
          <DropdownMenuGroup className="py-2 ">
            <div>
              <SectionHeading className="pb-1 text-sm font-semibold text-gray-800">
                Date
              </SectionHeading>
              <div className="flex items-center justify-between gap-0">
                <DatePicker
                  showIcone={false}
                  className={"rounded-none rounded-l"}
                  placeholder={"Start date"}
                  date={fromDate}
                  setDate={setFromDate}
                />
                <DatePicker
                  showIcone={false}
                  className={"rounded-none rounded-r"}
                  placeholder={"End date"}
                  date={toDate}
                  setDate={setToDate}
                />
              </div>
            </div>
          </DropdownMenuGroup>
          {(currentTab === "all" ||
            currentTab === undefined ||
            currentTab === "recent") && (
            <DropdownMenuGroup className="py-2">
              <div>
                <SectionHeading className="text-gray-80 pb-1 text-sm font-semibold">
                  Method
                </SectionHeading>
                <Select
                  value={transactionMethod}
                  onValueChange={(value) => {
                    setTransactionMethod(value);
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Show all" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    <SelectItem value="withdrawal">Withdrawal</SelectItem>
                    <SelectItem value="payout">Payout</SelectItem>
                    <SelectItem value="funding">Funding</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </DropdownMenuGroup>
          )}
          <DropdownMenuGroup className="py-2">
            <div>
              <SectionHeading className="text-gray-80 pb-1 text-sm font-semibold">
                Status
              </SectionHeading>
              <Select
                value={transactionStatus}
                onValueChange={(value) => {
                  setTransactionStatus(value);
                }}
              >
                <SelectTrigger className="w-full">
                  <SelectValue
                    placeholder="Show all"
                    className="text-gray-500"
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="success">Successful</SelectItem>
                  <SelectItem value="pending">Pending</SelectItem>
                  <SelectItem value="failed">Failed</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </DropdownMenuGroup>
          <DropdownMenuGroup className="py-2">
            <div>
              <SectionHeading className="text-gray-80 pb-1 text-sm font-semibold">
                Currency
              </SectionHeading>
              <CurrencyDropdown
                value={currency}
                onValueChange={(val) => {
                  setCurrency(val);
                  handleCurrencyChange(val);
                }}
                clasNmae="w-full justify-between bg-white"
              />
            </div>
          </DropdownMenuGroup>
          <DropdownMenuGroup className="py-2">
            <div>
              <SectionHeading className="text-gray-80 pb-1 text-sm font-semibold">
                Amount
              </SectionHeading>
              <div className="flex items-center justify-between gap-4">
                <Select
                  value={amountRange}
                  onValueChange={(value) => {
                    setAmountRange(value);
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue
                      placeholder="Show all"
                      className="text-gray-500"
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="=">Specific Amount</SelectItem>
                    <SelectItem value=">">More than</SelectItem>
                    <SelectItem value="<">Less than</SelectItem>
                  </SelectContent>
                </Select>
                <Input
                  value={amount}
                  placeholder={"Amount"}
                  inputMode={"numeric"}
                  onChange={(evt) => handleChange(evt.target.value)}
                />
              </div>
            </div>
          </DropdownMenuGroup>
          <DropdownMenuGroup className="">
            <div className="my-5 flex items-center justify-between">
              <Button
                type={"button"}
                variant="outline"
                size={"lg"}
                onClick={() => {
                  clearFilter();
                }}
              >
                Clear
              </Button>
              <Button
                type={"button"}
                variant="outline"
                size={"lg"}
                onClick={downloadCSV}
                className="mx-2 bg-primary text-white"
              >
                Download CSV
              </Button>
              <Button
                type={"button"}
                size={"lg"}
                className=""
                onClick={() => {
                  handleFilter();
                }}
              >
                Filter
              </Button>
            </div>
          </DropdownMenuGroup>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default React.memo(TransactionsFilters);
