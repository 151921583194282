import { createFileRoute } from "@tanstack/react-router";
import { DollarSign, ArrowDownToLine } from "lucide-react";
import React from "react";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import { PeopleIcon } from "~/assets/icons";
import {
  OverviewPerformanceChart,
  OverviewPerformanceChart2,
} from "~/components/Charts";
import {
  OverviewBalanceCard,
  OverviewMetrics,
  PendingAdminActions,
} from "~/components/DashboardComponents/overview";
import { Divider } from "~/components/Divider";
import { DefaultInputField } from "~/components/Inputs";
import { TabButton } from "~/components/Tabs";
import { WalletTransactionModal } from "~/components/TransactionDetails";
import { Header5Medium } from "~/components/Typography";
import { NumberBadge } from "~/components/Badges";
import {
  CardFooter,
  CardHeader,
  CardRoot,
  CardTitle,
} from "~/components/layouts/Card";
import { MetricsCard } from "~/components/molecules/MetricsCard";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import { TablePagination } from "~/components/organisms/table/table-pagination";
import { AppTable } from "~/components/table/table";
import { CaretDown } from "~/assets/icons";
import {
  amountColumn,
  createdAtColumn,
  receiverColumn,
  senderColumn,
  initiatorColumn,
  statusColumn,
  statusDotColumn,
  transactionTypeColumn,
} from "~/components/table/transaction";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import {
  useAccountData,
  useAllBusinessTransactions,
  useBusinessTransactions,
  useGetBusinessId,
} from "~/hooks/use-business";
import { useConvert } from "~/hooks/use-currency-rates";
import { useModal } from "~/hooks/use-modal";
import { useSharedFormatter } from "~/hooks/use-shared-formatter";
import { useUserMetrics } from "~/hooks/use-user-metrics";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import { serialNo } from "~/libs";
import { abbrevCurrencyValue } from "~/libs/currency.helpers";
import { BalanceMap } from "~/libs/factories/balance-map";
import useSearch, { useCountries } from "~/hooks";
import TransactionPerformance from "~/components/Charts";
import { CountryI } from "~/general/interfaces";
import TransactionsFilters from "~/components/TransactionsFilters";
import AddRuleModal from "~/components/Modal/AddRuleModal";

export const Route = createFileRoute("/dashboard/overview")({
  component: Page,
});

export function Page() {
  const { data: user } = useAccountData();
  const { data: metrics } = useUserMetrics();

  const { currency } = useSharedFormatter();
  const transactions = useConvert(
    currency.code,
    BalanceMap.create(metrics?.transactions?.volume)
  );
  const formatForCurrency = abbrevCurrencyValue(currency.code);

  return (
    <div className={"mt-[32px] flex w-full flex-col gap-4 pb-4"}>
      <Header5Medium className="text-gray-800">
        Welcome {user?.firstName},
      </Header5Medium>

      <OverviewBalanceCard />

      <OverviewMetrics />

      <div className="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-stretch">
        <div className="flex w-full flex-1 flex-col gap-4">
          <OverviewPerformanceChart2 />
          {/* <OverviewPerformanceChart /> */}

          <div className="flex flex-col gap-4">
            <MetricsCard
              variant="outline"
              Icon={<PeopleIcon />}
              caption="Users"
              data={[
                {
                  title: "Registered",
                  value: serialNo(safeNum(metrics?.totalUsers?.registered, 0)),
                },
                {
                  title: "Active",
                  value: serialNo(safeNum(metrics?.totalUsers?.active, 0)),
                },
              ]}
            />
            <MetricsCard
              variant="outline"
              Icon={<DollarSign />}
              caption="Transactions"
              data={[
                {
                  title: "Count",
                  value: serialNo(safeNum(metrics?.transactions?.count, 0)),
                },
                {
                  title: "Volume",
                  value: formatForCurrency(transactions.data.amount),
                },
              ]}
            />
          </div>
        </div>

        <div className="flex w-full flex-1 flex-col">
          <PendingAdminActions />
        </div>
      </div>
      <Divider />
      <AddRuleModal />
      <WalletLog />
    </div>
  );
}

function WalletLog({ title }: { title?: string }) {
  const table_columns = useMemo(
    () => [
      statusDotColumn,
      createdAtColumn,
      senderColumn,
      receiverColumn,
      initiatorColumn,
      transactionTypeColumn,
      amountColumn,
      statusColumn,
    ],
    []
  );

  const [currentTab, setCurrentTab] = useState("");
  const businessId = useGetBusinessId();
  const [page, setPage] = useState<number>(1);
  const modal = useModal();
  const { data: countries } = useCountries();
  const [open, setOpen] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [currency, setCurrency] = React.useState<CountryI>(
    countries.filter((val: CountryI) => val.iso2 === "US")[0]
  );
  const [currencyChanged, setCurrencyChanged] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [transactionMethod, setTransactionMethod] = useState<string>("");
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [amount, setAmount] = React.useState<string>("");
  const [amountRange, setAmountRange] = React.useState<string>("");

  const clearFilter = () => {
    setOpen(false);
    setShowFilter(false);
    setTransactionMethod("");
    setTransactionStatus("");
    setAmount("");
    setAmountRange("");
    setFromDate("");
    setToDate("");
    setCurrency(countries.filter((val: CountryI) => val.iso2 === "US")[0]);
    setSelectedCurrency("");
    refetch();
  };

  const handleFilter = () => {
    setOpen(false);
    setShowFilter(true);
  };

  const filteredItems = useMemo(
    () => [
      {
        title: "StartDate",
        value: fromDate ? format(fromDate, "dd/MM/yy") : "",
      },
      {
        title: "EndDate",
        value: toDate ? format(toDate, "dd/MM/yy") : "",
      },
      { title: "Method", value: transactionMethod ?? "" },
      { title: "Status", value: transactionStatus ?? "" },
      { title: "Currency", value: selectedCurrency ?? "" },
      { title: "Amt", value: amount ? `${amountRange}${amount}` : "" },
    ],
    [transactionMethod, fromDate, toDate, transactionStatus, currency, amount]
  );

  const filteredTab = useMemo(() => {
    let tab = "";

    if (currentTab === "recent") {
      tab = "";
    }

    if (currentTab === "admin") {
      tab = "admin";
    }

    return tab;
  }, [currentTab]);

  const handleCurrencyChange = (newCurrency: CountryI) => {
    if (currency !== newCurrency) {
      setSelectedCurrency(newCurrency.currency);
    }
  };

  const { isLoading, data, refetch } = useAllBusinessTransactions(
    {
      businessId,
      page: page,
      currency:
        open === false && showFilter && selectedCurrency
          ? selectedCurrency
          : "",
      amount:
        open === false && showFilter && amount && amountRange
          ? `${amountRange}${amount}`
          : "",
      startDate:
        open === false && showFilter && fromDate
          ? `${fromDate?.split("/")?.reverse()?.join("-")}`
          : "",
      endDate:
        open === false && showFilter && toDate
          ? `${toDate?.split("/")?.reverse()?.join("-")}`
          : "",
      flowType:
        open === false && showFilter && transactionMethod
          ? transactionMethod !== "all"
            ? transactionMethod.toUpperCase()
            : ""
          : "",
      status:
        open === false && showFilter && transactionStatus
          ? transactionStatus
          : "",
    },
    {
      enabled: !!businessId,
    }
  );

  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(data.data, "flow_type", "method");

  const tabs = [
    { label: "Recent Transactions", value: "recent", isActive: true },
    { label: "Admin Activity", value: "admin", isActive: false },
  ];

  return (
    <Tabs defaultValue={"recent"} className="-mt-5 mb-10">
      <CardHeader className="mb-[0px] flex items-center justify-start px-[20px]">
        <CardTitle className="pr-3 font-normal text-gray-500">
          History
        </CardTitle>
        <NumberBadge label="pending" count={data.meta.total ?? 0} />
      </CardHeader>
      <CardRoot>
        <div
          className={
            "no-scrollbar flex flex-col items-start justify-between gap-4 overflow-x-auto px-4 pb-2 pt-5 md:flex-row"
          }
        >
          <TabsList className={"gap-x-5 bg-transparent"}>
            {tabs.map((e) => (
              <TabsTrigger
                key={e.label}
                value={e.value}
                disabled={!e.isActive}
                onClick={() => setCurrentTab(e.value)}
                asChild
              >
                <TabButton>{e.label}</TabButton>
              </TabsTrigger>
            ))}
          </TabsList>

          <div className="flex flex-wrap items-center gap-4 md:flex-nowrap">
            {showFilter && (
              <div className="-mr-3 flex w-full items-center text-sm font-semibold text-[#3BB75E]">
                <p className="flex">Filtered By:</p>
                <p className="flex pl-1">
                  {filteredItems?.map((item) => (
                    <p key={`${item.title}-${item.value}`}>
                      {item.value && <p>{`${item.title}(${item.value}),`}</p>}
                    </p>
                  ))}
                </p>
              </div>
            )}
            <TransactionsFilters
              isHistory={data.data.length === 0}
              open={open}
              setOpen={setOpen}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              clearFilter={clearFilter}
              handleFilter={handleFilter}
              transactionMethod={transactionMethod}
              setTransactionMethod={setTransactionMethod}
              transactionStatus={transactionStatus}
              setTransactionStatus={setTransactionStatus}
              amount={amount}
              setAmount={setAmount}
              amountRange={amountRange}
              setAmountRange={setAmountRange}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              currency={currency}
              setCurrency={setCurrency}
              handleCurrencyChange={handleCurrencyChange}
            />
            {/* <Download /> */}
            <DefaultInputField
              placeholder="Search"
              value={searchTerm}
              setValue={handleSearchChange}
              disabled={data.data.length === 0}
            />
          </div>
        </div>

        <Divider className="my-[5px]" />

        <EmptyStateOverlay
          isEmpty={data.data.length === 0 && !isLoading}
          content={
            <EmptyStateDescription>
              Actions and Transactions will appear here
            </EmptyStateDescription>
          }
        >
          <div className="no-scrollbar overflow-auto">
            <TabsContent value={"recent"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"admin"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
          </div>
        </EmptyStateOverlay>

        {data.meta.total !== 0 && (
          <CardFooter className="py-3">
            <TablePagination
              data={{
                page: data.meta.page,
                pageCount: data.meta.pageCount,
                pageSize: data.meta.pageSize,
                total: data.meta.total,
                setPage: setPage,
              }}
            />
          </CardFooter>
        )}

        <WalletTransactionModal />
      </CardRoot>
    </Tabs>
  );
}

export function Download() {
  const filterItems = React.useMemo(
    () => [
      {
        label: "All",
        icon: <></>,
        link: "/settings/profile",
      },
      // {
      //   label: "Settings",
      //   icon: (
      //     <SettingsIcon
      //       absoluteStrokeWidth={false}
      //       size={"1.2rem"}
      //       stroke={"currentColor"}
      //       strokeWidth={1.3}
      //     />
      //   ),
      //   link: "/settings",
      // },
    ],
    []
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex cursor-not-allowed items-center text-sm text-[#57584E]">
          <div className="mr-[5px]">
            <ArrowDownToLine size={12} />
          </div>
          Download
        </div>
      </DropdownMenuTrigger>

      {/* <DropdownMenuContent
        sideOffset={10}
        align="end"
        className="min-w-[150px]"
      >
        <DropdownMenuGroup>
          {filterItems.map((item, i) => (
            <DropdownMenuItem
              key={item.label}
              className="space-x-2 text-gray-700"
              onClick={() => {}}
            >
              {item.icon}
              <span className={"text-base font-normal"}>{item.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent> */}
    </DropdownMenu>
  );
}
