import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import { useResetPassword } from "~/api/codegen/walletosComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { AuthScreenNav } from "~/components/Navbars";
import { notifyApiError } from "~/components/Toast";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { useResetSession } from "~/hooks/use-auth";

export const Route = createFileRoute("/auth/forgot-password")({
  component: Page,
});

const schema = z.object({
  email_address: z
    .string({ required_error: "Enter email" })
    .email({ message: "Please enter a valid email" }),
});

type FormType = z.infer<typeof schema>;

function Page() {
  const navigate = useNavigate();

  const form = useForm<FormType>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });
  const [, setSession] = useResetSession();
  const reset_password = useResetPassword();

  const onSubmit = form.handleSubmit((form_state) => {
    reset_password
      .mutateAsync({
        body: { email: form_state.email_address },
      })
      .then(() => {
        setSession({ email: form_state.email_address });
        return navigate({ to: "/auth/reset-password" });
      })
      .catch(notifyApiError);
  });

  return (
    <Form {...form}>
      <AuthScreenNav />

      <CardRoot className="mx-auto w-full max-w-[612px]">
        <CardHeader>
          <CardTitle className="font-body text-lg font-bold">
            Recover Password
          </CardTitle>
        </CardHeader>

        <CardSeparator />

        <CardBody>
          <form onSubmit={onSubmit} className="flex flex-col gap-10">
            <div className="flex flex-col gap-4">
              <FormField
                name="email_address"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Enter email address</FormLabel>
                      <FormControl>
                        <Input
                          type="email"
                          placeholder="someone@domain.com"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>

            <Button size={"lg"}>
              <LoadingButtonContent loading={reset_password.isPending}>
                Reset Password
              </LoadingButtonContent>
            </Button>
          </form>
        </CardBody>
      </CardRoot>
    </Form>
  );
}
