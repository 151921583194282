// import * as React from 'react'

// export const Route = createFileRoute('/dashboard/wallet/rules')({
//   component: RouteComponent,
// })

// function RouteComponent() {
//   return 'Hello /dashboard/wallet/rules!'
// }

"use client";

import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { Button } from "~/@/components/ui/button";
import { Switch } from "~/@/components/ui/switch";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import { Badge } from "~/@/components/ui/badge";
import { MoreVertical, Plus } from "lucide-react";

interface Rule {
  id: number;
  minAmount: string;
  maxAmount: string;
  requiredPeople: string[];
  isActive: boolean;
}

export const Route = createFileRoute("/dashboard/wallet/rules")({
  component: ApprovalsPage,
});

export default function ApprovalsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rules, setRules] = useState<Rule[]>([
    {
      id: 1,
      minAmount: "2,000.00",
      maxAmount: "10,000",
      requiredPeople: ["Adetobi Ayola"],
      isActive: true,
    },
    {
      id: 2,
      minAmount: "2,000.00",
      maxAmount: "10,000",
      requiredPeople: ["Adetobi Ayola"],
      isActive: true,
    },
    {
      id: 3,
      minAmount: "2,000.00",
      maxAmount: "10,000",
      requiredPeople: ["Adetobi Ayola"],
      isActive: true,
    },
    {
      id: 4,
      minAmount: "2,000.00",
      maxAmount: "10,000",
      requiredPeople: ["Adetobi Ayola"],
      isActive: true,
    },
  ]);

  const toggleRule = (id: number) => {
    setRules(
      rules.map((rule) =>
        rule.id === id ? { ...rule, isActive: !rule.isActive } : rule,
      ),
    );
  };

  const deleteRule = (id: number) => {
    setRules(rules.filter((rule) => rule.id !== id));
  };

  return (
    <div className="mx-auto min-h-screen max-w-7xl p-6">
      <div className="mb-5 flex w-full items-center justify-start">
        <h1 className="text-2xl font-semibold text-gray-800">Approval Rules</h1>
      </div>
      <div className="mb-8 flex w-full flex-col items-end justify-center rounded-lg bg-white p-4">
        <Button
          onClick={() => setIsModalOpen(false)}
          className="bg-blue-500 text-white hover:bg-blue-600"
        >
          <Plus className="mr-2 h-4 w-4" /> Add Rule
        </Button>
        <div className="w-full rounded-lg bg-transparent">
          <div className="grid gap-px bg-transparent sm:grid-cols-2">
            {rules.map((rule, index) => (
              <div key={rule.id} className="mb-3 mr-3 rounded-lg bg-white p-6">
                <div className="mb-4 flex items-start justify-between">
                  <div>
                    <p className="mb-1 text-sm text-gray-500">Rule</p>
                    <p className="text-sm text-gray-700">
                      if amount is greater or equal to ${rule.minAmount} and
                      less than ${rule.maxAmount}
                    </p>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon" className="h-8 w-8">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={() => deleteRule(rule.id)}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <p className="mb-2 text-sm text-gray-500">Requires</p>
                    <div className="flex gap-2">
                      {rule.requiredPeople.map((person) => (
                        <Badge
                          key={person}
                          variant="secondary"
                          className="rounded-full bg-gray-100 px-2 py-0.5 text-xs text-gray-600"
                        >
                          {person}
                        </Badge>
                      ))}
                    </div>
                  </div>
                  <Switch
                    checked={rule.isActive}
                    onCheckedChange={() => toggleRule(rule.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
