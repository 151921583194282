import { take, takeLast, toUpper } from "ramda";

export const shortenId = (id: string) => {
  return toUpper(`${take(3, id)}...${takeLast(3, id)}`);
};

export const truncateString = (str: string, num: number) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str?.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str?.slice(0, num)}...`;
};
