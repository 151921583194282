import React from "react";
import { Button } from "~/@/components/ui/button";
import { SectionHeading } from "~/components/atoms/heading";
import { CardSeparator } from "~/components/layouts/Card";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogRemote,
  DialogRemoteTrigger,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { Textarea } from "~/@/components/ui/textarea";
import { Alert } from "~/components/atoms/Alert";
import { Checkbox } from "~/@/components/ui/checkbox";
import { useForm } from "react-hook-form";
import { ChipSelector } from "~/components/Inputs";
import { notify, notifyApiError } from "~/components/Toast";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "~/@/components//ui/form";
import { AmountInput } from "~/components/Inputs";
import { Input } from "~/@/components/ui/input";
import { CountryI } from "~/general/interfaces";
import { query_client } from "~/contexts/react-query";
import {
  useEditApplicationToken,
  useGetTokens,
} from "~/api/codegen/liquidityComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { useModal, useModalHandle } from "~/hooks/use-modal";
import { ApplicationTokenTypes } from "~/libs/types.helper";
import { useNavigate } from "@tanstack/react-router";
import { useGetBusinessId } from "~/hooks/use-business";
import { useCreateApplicationTokenAccount } from "~/api/codegen/liquidityComponents";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { formatNumber, InputFormatNumber } from "~/libs/currency.helpers";
import { useCountries } from "~/hooks";

const schema = z.object({
  name: z.string(),
  context: z.string(),
  currency: z.string(),
  amount: z.string(),
  collection: z.array(z.string()).optional(),
  amountLocked: z.boolean(),
  url: z.string().optional(),
});
const dataList = ["Name", "Email", "Phone"];

export function EditPaymentLink({
  updateKyc,
  sourceTable,
  selectedData,
  isShow,
  setIsShow,
}: {
  updateKyc?: () => void;
  sourceTable?: string;
  selectedData: ApplicationTokenTypes;
  isShow?: boolean;
  setIsShow?: (value: boolean) => void;
}) {
  // const modal = useModal();
  const navigate = useNavigate();
  const { data: countries } = useCountries();
  const businessId = useGetBusinessId();

  const [list, setList] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState(null);
  const [source, setSource] = React.useState("");
  const [currency, setCurrency] = React.useState<CountryI>();
  const form = useForm<z.infer<typeof schema>>();

  const { refetch } = useGetTokens(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        type: "CHECKOUT_WIDGET",
        page: 1,
        limit: 5,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const mutation = useEditApplicationToken();

  useEffect(() => {
    setSelected(selectedData);
    if (sourceTable === "Payment Link Details") {
      setSource(sourceTable);
    }
  }, [selectedData, sourceTable]);

  useEffect(() => {
    // console.log("selected", selected);
    if (selected) {
      const numericValue =
        selected?.application?.metadata?.extra?.amount.replace(/[^0-9]/g, "");
      const newAmount = formatNumber(numericValue ?? "0");

      const currency = countries?.filter(
        (country) =>
          country?.currency === selected?.application?.metadata?.currency,
      );

      form.setValue(
        "name",
        (selected?.application?.metadata?.extra?.slug as string) ?? "",
      );
      form.setValue("amount", newAmount);
      form.setValue(
        "amountLocked",
        (selected?.application?.metadata?.extra?.amountLocked as boolean) ??
          false,
      );
      form.setValue(
        "currency",
        (selected?.application?.metadata?.currency as string) ?? "",
      );
      form.setValue("url", selected?.url);

      setList(selected?.application?.metadata?.extra?.dataCollections ?? []);
      setCurrency(currency[currency.length - 1]);
    }
  }, [selected, selectedData]);

  const modal = useModalHandle("invite_user");

  return (
    <DialogRemote
      id="invite_user"
      open={isShow}
      onOpenChange={(open) => {
        if (!open) {
          setIsShow?.(false);
        }
      }}
      // onOpenChange={(open) => {
      //   console.log("remote check", open);
      //   if (!open) {
      //     modal.hide();
      //   }
      // }}
    >
      <DialogContent showClose className="h-[90vh] max-w-xl px-5">
        <DialogHeader className="space-y-0 px-5">
          <DialogTitle className="flex">
            <SectionHeading>
              <span className="text-lg text-gray-600">Edit Payment Link</span>
            </SectionHeading>
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <CardSeparator className="-mx-5" />
        <ScrollArea className="max-h-[40rem]">
          <>
            <form
              className="-mt-5 flex flex-col px-6"
              onSubmit={form.handleSubmit(async (data) => {
                try {
                  if (!data.currency) {
                    notify("error", "Select a currency!");
                    return;
                  }
                  await mutation.mutateAsync({
                    pathParams: {
                      businessId,
                      token: selected?.application?.token,
                    },
                    body: {
                      slug: data.name,
                      // amount: data.amount,
                      // amountLocked: data.amountLocked ?? true,
                    },
                  });

                  await refetch();
                  notify("success", "Payment link updated successfully!");
                  modal.hide();
                } catch (err) {
                  notifyApiError(err);
                }
              })}
            >
              <Form {...form}>
                <div className="mt-[30px] flex w-full flex-col gap-4">
                  <FormField
                    control={form.control}
                    name={"name"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Payment name</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Enter link name" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name={"amount"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Amount</FormLabel>
                          <FormControl>
                            <AmountInput
                              country={currency}
                              setCountry={(value) => {
                                setCurrency(value);
                                form.setValue(
                                  "currency",
                                  value.currency as string,
                                );
                              }}
                              setValue={(value) => {
                                form.setValue(field.name, value as string);
                              }}
                              value={field.value}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name={"amountLocked"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel
                            className={
                              "flex items-center gap-x-2 text-gray-500"
                            }
                          >
                            <FormControl>
                              <Checkbox
                                onCheckedChange={(value) => {
                                  form.setValue(field.name, value as boolean);
                                }}
                                checked={field.value ?? true}
                              />
                            </FormControl>
                            <span className={"font-normal"}>Lock account</span>
                          </FormLabel>
                        </FormItem>
                      );
                    }}
                  />

                  <CardSeparator className="my-2" />

                  <FormField
                    control={form.control}
                    name={"collection"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Data collection</FormLabel>
                          <FormControl>
                            <ChipSelector
                              className="w-full"
                              list={dataList}
                              selected={list}
                              setSelected={setList}
                              placeholder="Select data you want to accept"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name={"context"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Partner context</FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              className="h-40"
                              placeholder={`"employee": {
        "name":       "sonoo",
        "salary":      56000,
        "married":    true
  }`}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <CardSeparator className="my-2" />
                  <FormField
                    control={form.control}
                    name={"url"}
                    render={({ field }) => {
                      return (
                        <FormItem className="pb-3">
                          <FormLabel>Customize URL</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="https://centryos.xyz/"
                              disabled
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
                <Alert type={"info"} className="my-5">
                  Payment link expires in 12 months
                </Alert>
                <CardSeparator className="-mx-8" />
                <div className="my-[20px] flex items-center justify-between">
                  <Button
                    type={"reset"}
                    variant="outline"
                    size={"lg"}
                    onClick={() => {
                      modal.hide();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    size={"lg"}
                    className=""
                    // disabled={
                    //   !form.formState.isDirty || !form.formState.isValid
                    // }
                  >
                    <LoadingButtonContent loading={mutation.isPending}>
                      Update
                    </LoadingButtonContent>
                  </Button>
                </div>
              </Form>
            </form>
          </>
        </ScrollArea>
      </DialogContent>
    </DialogRemote>
  );
}
