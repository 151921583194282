export function NotificationIcon({ color }: { color?: string }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
    >
      <path
        d="M0 0 C1.32 0 2.64 0 4 0 C4 0.66 4 1.32 4 2 C4.66 2 5.32 2 6 2 C6.13277344 2.61488281 6.26554688 3.22976562 6.40234375 3.86328125 C6.66724609 5.07564453 6.66724609 5.07564453 6.9375 6.3125 C7.11152344 7.11300781 7.28554687 7.91351563 7.46484375 8.73828125 C7.99886159 11.145401 7.99886159 11.145401 9 14 C7.35 14 5.7 14 4 14 C3.67 14.99 3.34 15.98 3 17 C2.01 16.67 1.02 16.34 0 16 C0 15.34 0 14.68 0 14 C-1.65 14 -3.3 14 -5 14 C-4.01 10.04 -3.02 6.08 -2 2 C-1.34 2 -0.68 2 0 2 C0 1.34 0 0.68 0 0 Z "
        fill="#C3CCD4"
        transform="translate(8,2)"
      />
    </svg>
  );
}
