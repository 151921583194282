/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as WithdrawImport } from './routes/withdraw'
import { Route as TsuccessImport } from './routes/tsuccess'
import { Route as SigninImport } from './routes/signin'
import { Route as SettingsImport } from './routes/settings'
import { Route as PayoutImport } from './routes/payout'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as ConvertImport } from './routes/convert'
import { Route as IndexImport } from './routes/index'
import { Route as SettingsIndexImport } from './routes/settings/index'
import { Route as DashboardIndexImport } from './routes/dashboard/index'
import { Route as SignupSessionIdImport } from './routes/signup.$sessionId'
import { Route as SettingsWalletsImport } from './routes/settings/wallets'
import { Route as SettingsTeamImport } from './routes/settings/team'
import { Route as SettingsProfileImport } from './routes/settings/profile'
import { Route as SettingsNotificationsImport } from './routes/settings/notifications'
import { Route as SettingsApiImport } from './routes/settings/api'
import { Route as SettingsAccountsImport } from './routes/settings/accounts'
import { Route as DashboardOverviewImport } from './routes/dashboard/overview'
import { Route as DashboardAuditImport } from './routes/dashboard/audit'
import { Route as DashboardApiImport } from './routes/dashboard/api'
import { Route as AuthResetPasswordImport } from './routes/auth.reset-password'
import { Route as AuthNewPasswordImport } from './routes/auth.new-password'
import { Route as AuthForgotPasswordImport } from './routes/auth.forgot-password'
import { Route as SettingsWalletsIndexImport } from './routes/settings/wallets/index'
import { Route as SettingsWalletsVaultWalletImport } from './routes/settings/wallets/vault-wallet'
import { Route as SettingsWalletsUserWalletImport } from './routes/settings/wallets/user-wallet'
import { Route as SettingsWalletsRevenueWalletImport } from './routes/settings/wallets/revenue-wallet'
import { Route as SaSignupSessionIdImport } from './routes/sa.signup.$sessionId'
import { Route as DashboardWalletRulesImport } from './routes/dashboard/wallet/rules'
import { Route as DashboardWalletUsersIndexImport } from './routes/dashboard/wallet/users/index'
import { Route as DashboardWalletSpendIndexImport } from './routes/dashboard/wallet/spend/index'
import { Route as DashboardWalletUsersUseridImport } from './routes/dashboard/wallet/users.$user_id'
import { Route as DashboardWalletSpendSetupImport } from './routes/dashboard/wallet/spend/setup'
import { Route as DashboardWalletSpendPaymentLinkSetupImport } from './routes/dashboard/wallet/spend/paymentLinkSetup'
import { Route as DashboardWalletSpendMarketplaceImport } from './routes/dashboard/wallet/spend/marketplace'
import { Route as DashboardWalletSpendFundImport } from './routes/dashboard/wallet/spend/fund'
import { Route as DashboardWalletSpendAddAccountImport } from './routes/dashboard/wallet/spend/addAccount'
import { Route as DashboardWalletRevenueRequestPayoutImport } from './routes/dashboard/wallet/revenue/requestPayout'
import { Route as DashboardWalletRevenueOverviewImport } from './routes/dashboard/wallet/revenue/overview'
import { Route as DashboardWalletSpendTokenTokenImport } from './routes/dashboard/wallet/spend/token.$token'
import { Route as DashboardWalletSpendSubwalletsIdImport } from './routes/dashboard/wallet/spend/subwallets.$id'

// Create/Update Routes

const WithdrawRoute = WithdrawImport.update({
  id: '/withdraw',
  path: '/withdraw',
  getParentRoute: () => rootRoute,
} as any)

const TsuccessRoute = TsuccessImport.update({
  id: '/tsuccess',
  path: '/tsuccess',
  getParentRoute: () => rootRoute,
} as any)

const SigninRoute = SigninImport.update({
  id: '/signin',
  path: '/signin',
  getParentRoute: () => rootRoute,
} as any)

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const PayoutRoute = PayoutImport.update({
  id: '/payout',
  path: '/payout',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const ConvertRoute = ConvertImport.update({
  id: '/convert',
  path: '/convert',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsIndexRoute = SettingsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SettingsRoute,
} as any)

const DashboardIndexRoute = DashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardRoute,
} as any)

const SignupSessionIdRoute = SignupSessionIdImport.update({
  id: '/signup/$sessionId',
  path: '/signup/$sessionId',
  getParentRoute: () => rootRoute,
} as any)

const SettingsWalletsRoute = SettingsWalletsImport.update({
  id: '/wallets',
  path: '/wallets',
  getParentRoute: () => SettingsRoute,
} as any)

const SettingsTeamRoute = SettingsTeamImport.update({
  id: '/team',
  path: '/team',
  getParentRoute: () => SettingsRoute,
} as any)

const SettingsProfileRoute = SettingsProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => SettingsRoute,
} as any)

const SettingsNotificationsRoute = SettingsNotificationsImport.update({
  id: '/notifications',
  path: '/notifications',
  getParentRoute: () => SettingsRoute,
} as any)

const SettingsApiRoute = SettingsApiImport.update({
  id: '/api',
  path: '/api',
  getParentRoute: () => SettingsRoute,
} as any)

const SettingsAccountsRoute = SettingsAccountsImport.update({
  id: '/accounts',
  path: '/accounts',
  getParentRoute: () => SettingsRoute,
} as any)

const DashboardOverviewRoute = DashboardOverviewImport.update({
  id: '/overview',
  path: '/overview',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardAuditRoute = DashboardAuditImport.update({
  id: '/audit',
  path: '/audit',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardApiRoute = DashboardApiImport.update({
  id: '/api',
  path: '/api',
  getParentRoute: () => DashboardRoute,
} as any)

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/auth/reset-password',
  path: '/auth/reset-password',
  getParentRoute: () => rootRoute,
} as any)

const AuthNewPasswordRoute = AuthNewPasswordImport.update({
  id: '/auth/new-password',
  path: '/auth/new-password',
  getParentRoute: () => rootRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/auth/forgot-password',
  path: '/auth/forgot-password',
  getParentRoute: () => rootRoute,
} as any)

const SettingsWalletsIndexRoute = SettingsWalletsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SettingsWalletsRoute,
} as any)

const SettingsWalletsVaultWalletRoute = SettingsWalletsVaultWalletImport.update(
  {
    id: '/vault-wallet',
    path: '/vault-wallet',
    getParentRoute: () => SettingsWalletsRoute,
  } as any,
)

const SettingsWalletsUserWalletRoute = SettingsWalletsUserWalletImport.update({
  id: '/user-wallet',
  path: '/user-wallet',
  getParentRoute: () => SettingsWalletsRoute,
} as any)

const SettingsWalletsRevenueWalletRoute =
  SettingsWalletsRevenueWalletImport.update({
    id: '/revenue-wallet',
    path: '/revenue-wallet',
    getParentRoute: () => SettingsWalletsRoute,
  } as any)

const SaSignupSessionIdRoute = SaSignupSessionIdImport.update({
  id: '/sa/signup/$sessionId',
  path: '/sa/signup/$sessionId',
  getParentRoute: () => rootRoute,
} as any)

const DashboardWalletRulesRoute = DashboardWalletRulesImport.update({
  id: '/wallet/rules',
  path: '/wallet/rules',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardWalletUsersIndexRoute = DashboardWalletUsersIndexImport.update({
  id: '/wallet/users/',
  path: '/wallet/users/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardWalletSpendIndexRoute = DashboardWalletSpendIndexImport.update({
  id: '/wallet/spend/',
  path: '/wallet/spend/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardWalletUsersUseridRoute = DashboardWalletUsersUseridImport.update(
  {
    id: '/wallet/users/$user_id',
    path: '/wallet/users/$user_id',
    getParentRoute: () => DashboardRoute,
  } as any,
)

const DashboardWalletSpendSetupRoute = DashboardWalletSpendSetupImport.update({
  id: '/wallet/spend/setup',
  path: '/wallet/spend/setup',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardWalletSpendPaymentLinkSetupRoute =
  DashboardWalletSpendPaymentLinkSetupImport.update({
    id: '/wallet/spend/paymentLinkSetup',
    path: '/wallet/spend/paymentLinkSetup',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardWalletSpendMarketplaceRoute =
  DashboardWalletSpendMarketplaceImport.update({
    id: '/wallet/spend/marketplace',
    path: '/wallet/spend/marketplace',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardWalletSpendFundRoute = DashboardWalletSpendFundImport.update({
  id: '/wallet/spend/fund',
  path: '/wallet/spend/fund',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardWalletSpendAddAccountRoute =
  DashboardWalletSpendAddAccountImport.update({
    id: '/wallet/spend/addAccount',
    path: '/wallet/spend/addAccount',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardWalletRevenueRequestPayoutRoute =
  DashboardWalletRevenueRequestPayoutImport.update({
    id: '/wallet/revenue/requestPayout',
    path: '/wallet/revenue/requestPayout',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardWalletRevenueOverviewRoute =
  DashboardWalletRevenueOverviewImport.update({
    id: '/wallet/revenue/overview',
    path: '/wallet/revenue/overview',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardWalletSpendTokenTokenRoute =
  DashboardWalletSpendTokenTokenImport.update({
    id: '/wallet/spend/token/$token',
    path: '/wallet/spend/token/$token',
    getParentRoute: () => DashboardRoute,
  } as any)

const DashboardWalletSpendSubwalletsIdRoute =
  DashboardWalletSpendSubwalletsIdImport.update({
    id: '/wallet/spend/subwallets/$id',
    path: '/wallet/spend/subwallets/$id',
    getParentRoute: () => DashboardRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/convert': {
      id: '/convert'
      path: '/convert'
      fullPath: '/convert'
      preLoaderRoute: typeof ConvertImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/payout': {
      id: '/payout'
      path: '/payout'
      fullPath: '/payout'
      preLoaderRoute: typeof PayoutImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/signin': {
      id: '/signin'
      path: '/signin'
      fullPath: '/signin'
      preLoaderRoute: typeof SigninImport
      parentRoute: typeof rootRoute
    }
    '/tsuccess': {
      id: '/tsuccess'
      path: '/tsuccess'
      fullPath: '/tsuccess'
      preLoaderRoute: typeof TsuccessImport
      parentRoute: typeof rootRoute
    }
    '/withdraw': {
      id: '/withdraw'
      path: '/withdraw'
      fullPath: '/withdraw'
      preLoaderRoute: typeof WithdrawImport
      parentRoute: typeof rootRoute
    }
    '/auth/forgot-password': {
      id: '/auth/forgot-password'
      path: '/auth/forgot-password'
      fullPath: '/auth/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof rootRoute
    }
    '/auth/new-password': {
      id: '/auth/new-password'
      path: '/auth/new-password'
      fullPath: '/auth/new-password'
      preLoaderRoute: typeof AuthNewPasswordImport
      parentRoute: typeof rootRoute
    }
    '/auth/reset-password': {
      id: '/auth/reset-password'
      path: '/auth/reset-password'
      fullPath: '/auth/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/api': {
      id: '/dashboard/api'
      path: '/api'
      fullPath: '/dashboard/api'
      preLoaderRoute: typeof DashboardApiImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/audit': {
      id: '/dashboard/audit'
      path: '/audit'
      fullPath: '/dashboard/audit'
      preLoaderRoute: typeof DashboardAuditImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/overview': {
      id: '/dashboard/overview'
      path: '/overview'
      fullPath: '/dashboard/overview'
      preLoaderRoute: typeof DashboardOverviewImport
      parentRoute: typeof DashboardImport
    }
    '/settings/accounts': {
      id: '/settings/accounts'
      path: '/accounts'
      fullPath: '/settings/accounts'
      preLoaderRoute: typeof SettingsAccountsImport
      parentRoute: typeof SettingsImport
    }
    '/settings/api': {
      id: '/settings/api'
      path: '/api'
      fullPath: '/settings/api'
      preLoaderRoute: typeof SettingsApiImport
      parentRoute: typeof SettingsImport
    }
    '/settings/notifications': {
      id: '/settings/notifications'
      path: '/notifications'
      fullPath: '/settings/notifications'
      preLoaderRoute: typeof SettingsNotificationsImport
      parentRoute: typeof SettingsImport
    }
    '/settings/profile': {
      id: '/settings/profile'
      path: '/profile'
      fullPath: '/settings/profile'
      preLoaderRoute: typeof SettingsProfileImport
      parentRoute: typeof SettingsImport
    }
    '/settings/team': {
      id: '/settings/team'
      path: '/team'
      fullPath: '/settings/team'
      preLoaderRoute: typeof SettingsTeamImport
      parentRoute: typeof SettingsImport
    }
    '/settings/wallets': {
      id: '/settings/wallets'
      path: '/wallets'
      fullPath: '/settings/wallets'
      preLoaderRoute: typeof SettingsWalletsImport
      parentRoute: typeof SettingsImport
    }
    '/signup/$sessionId': {
      id: '/signup/$sessionId'
      path: '/signup/$sessionId'
      fullPath: '/signup/$sessionId'
      preLoaderRoute: typeof SignupSessionIdImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/': {
      id: '/dashboard/'
      path: '/'
      fullPath: '/dashboard/'
      preLoaderRoute: typeof DashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/settings/': {
      id: '/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof SettingsIndexImport
      parentRoute: typeof SettingsImport
    }
    '/dashboard/wallet/rules': {
      id: '/dashboard/wallet/rules'
      path: '/wallet/rules'
      fullPath: '/dashboard/wallet/rules'
      preLoaderRoute: typeof DashboardWalletRulesImport
      parentRoute: typeof DashboardImport
    }
    '/sa/signup/$sessionId': {
      id: '/sa/signup/$sessionId'
      path: '/sa/signup/$sessionId'
      fullPath: '/sa/signup/$sessionId'
      preLoaderRoute: typeof SaSignupSessionIdImport
      parentRoute: typeof rootRoute
    }
    '/settings/wallets/revenue-wallet': {
      id: '/settings/wallets/revenue-wallet'
      path: '/revenue-wallet'
      fullPath: '/settings/wallets/revenue-wallet'
      preLoaderRoute: typeof SettingsWalletsRevenueWalletImport
      parentRoute: typeof SettingsWalletsImport
    }
    '/settings/wallets/user-wallet': {
      id: '/settings/wallets/user-wallet'
      path: '/user-wallet'
      fullPath: '/settings/wallets/user-wallet'
      preLoaderRoute: typeof SettingsWalletsUserWalletImport
      parentRoute: typeof SettingsWalletsImport
    }
    '/settings/wallets/vault-wallet': {
      id: '/settings/wallets/vault-wallet'
      path: '/vault-wallet'
      fullPath: '/settings/wallets/vault-wallet'
      preLoaderRoute: typeof SettingsWalletsVaultWalletImport
      parentRoute: typeof SettingsWalletsImport
    }
    '/settings/wallets/': {
      id: '/settings/wallets/'
      path: '/'
      fullPath: '/settings/wallets/'
      preLoaderRoute: typeof SettingsWalletsIndexImport
      parentRoute: typeof SettingsWalletsImport
    }
    '/dashboard/wallet/revenue/overview': {
      id: '/dashboard/wallet/revenue/overview'
      path: '/wallet/revenue/overview'
      fullPath: '/dashboard/wallet/revenue/overview'
      preLoaderRoute: typeof DashboardWalletRevenueOverviewImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/revenue/requestPayout': {
      id: '/dashboard/wallet/revenue/requestPayout'
      path: '/wallet/revenue/requestPayout'
      fullPath: '/dashboard/wallet/revenue/requestPayout'
      preLoaderRoute: typeof DashboardWalletRevenueRequestPayoutImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/addAccount': {
      id: '/dashboard/wallet/spend/addAccount'
      path: '/wallet/spend/addAccount'
      fullPath: '/dashboard/wallet/spend/addAccount'
      preLoaderRoute: typeof DashboardWalletSpendAddAccountImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/fund': {
      id: '/dashboard/wallet/spend/fund'
      path: '/wallet/spend/fund'
      fullPath: '/dashboard/wallet/spend/fund'
      preLoaderRoute: typeof DashboardWalletSpendFundImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/marketplace': {
      id: '/dashboard/wallet/spend/marketplace'
      path: '/wallet/spend/marketplace'
      fullPath: '/dashboard/wallet/spend/marketplace'
      preLoaderRoute: typeof DashboardWalletSpendMarketplaceImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/paymentLinkSetup': {
      id: '/dashboard/wallet/spend/paymentLinkSetup'
      path: '/wallet/spend/paymentLinkSetup'
      fullPath: '/dashboard/wallet/spend/paymentLinkSetup'
      preLoaderRoute: typeof DashboardWalletSpendPaymentLinkSetupImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/setup': {
      id: '/dashboard/wallet/spend/setup'
      path: '/wallet/spend/setup'
      fullPath: '/dashboard/wallet/spend/setup'
      preLoaderRoute: typeof DashboardWalletSpendSetupImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/users/$user_id': {
      id: '/dashboard/wallet/users/$user_id'
      path: '/wallet/users/$user_id'
      fullPath: '/dashboard/wallet/users/$user_id'
      preLoaderRoute: typeof DashboardWalletUsersUseridImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/': {
      id: '/dashboard/wallet/spend/'
      path: '/wallet/spend'
      fullPath: '/dashboard/wallet/spend'
      preLoaderRoute: typeof DashboardWalletSpendIndexImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/users/': {
      id: '/dashboard/wallet/users/'
      path: '/wallet/users'
      fullPath: '/dashboard/wallet/users'
      preLoaderRoute: typeof DashboardWalletUsersIndexImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/subwallets/$id': {
      id: '/dashboard/wallet/spend/subwallets/$id'
      path: '/wallet/spend/subwallets/$id'
      fullPath: '/dashboard/wallet/spend/subwallets/$id'
      preLoaderRoute: typeof DashboardWalletSpendSubwalletsIdImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/wallet/spend/token/$token': {
      id: '/dashboard/wallet/spend/token/$token'
      path: '/wallet/spend/token/$token'
      fullPath: '/dashboard/wallet/spend/token/$token'
      preLoaderRoute: typeof DashboardWalletSpendTokenTokenImport
      parentRoute: typeof DashboardImport
    }
  }
}

// Create and export the route tree

interface DashboardRouteChildren {
  DashboardApiRoute: typeof DashboardApiRoute
  DashboardAuditRoute: typeof DashboardAuditRoute
  DashboardOverviewRoute: typeof DashboardOverviewRoute
  DashboardIndexRoute: typeof DashboardIndexRoute
  DashboardWalletRulesRoute: typeof DashboardWalletRulesRoute
  DashboardWalletRevenueOverviewRoute: typeof DashboardWalletRevenueOverviewRoute
  DashboardWalletRevenueRequestPayoutRoute: typeof DashboardWalletRevenueRequestPayoutRoute
  DashboardWalletSpendAddAccountRoute: typeof DashboardWalletSpendAddAccountRoute
  DashboardWalletSpendFundRoute: typeof DashboardWalletSpendFundRoute
  DashboardWalletSpendMarketplaceRoute: typeof DashboardWalletSpendMarketplaceRoute
  DashboardWalletSpendPaymentLinkSetupRoute: typeof DashboardWalletSpendPaymentLinkSetupRoute
  DashboardWalletSpendSetupRoute: typeof DashboardWalletSpendSetupRoute
  DashboardWalletUsersUseridRoute: typeof DashboardWalletUsersUseridRoute
  DashboardWalletSpendIndexRoute: typeof DashboardWalletSpendIndexRoute
  DashboardWalletUsersIndexRoute: typeof DashboardWalletUsersIndexRoute
  DashboardWalletSpendSubwalletsIdRoute: typeof DashboardWalletSpendSubwalletsIdRoute
  DashboardWalletSpendTokenTokenRoute: typeof DashboardWalletSpendTokenTokenRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardApiRoute: DashboardApiRoute,
  DashboardAuditRoute: DashboardAuditRoute,
  DashboardOverviewRoute: DashboardOverviewRoute,
  DashboardIndexRoute: DashboardIndexRoute,
  DashboardWalletRulesRoute: DashboardWalletRulesRoute,
  DashboardWalletRevenueOverviewRoute: DashboardWalletRevenueOverviewRoute,
  DashboardWalletRevenueRequestPayoutRoute:
    DashboardWalletRevenueRequestPayoutRoute,
  DashboardWalletSpendAddAccountRoute: DashboardWalletSpendAddAccountRoute,
  DashboardWalletSpendFundRoute: DashboardWalletSpendFundRoute,
  DashboardWalletSpendMarketplaceRoute: DashboardWalletSpendMarketplaceRoute,
  DashboardWalletSpendPaymentLinkSetupRoute:
    DashboardWalletSpendPaymentLinkSetupRoute,
  DashboardWalletSpendSetupRoute: DashboardWalletSpendSetupRoute,
  DashboardWalletUsersUseridRoute: DashboardWalletUsersUseridRoute,
  DashboardWalletSpendIndexRoute: DashboardWalletSpendIndexRoute,
  DashboardWalletUsersIndexRoute: DashboardWalletUsersIndexRoute,
  DashboardWalletSpendSubwalletsIdRoute: DashboardWalletSpendSubwalletsIdRoute,
  DashboardWalletSpendTokenTokenRoute: DashboardWalletSpendTokenTokenRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

interface SettingsWalletsRouteChildren {
  SettingsWalletsRevenueWalletRoute: typeof SettingsWalletsRevenueWalletRoute
  SettingsWalletsUserWalletRoute: typeof SettingsWalletsUserWalletRoute
  SettingsWalletsVaultWalletRoute: typeof SettingsWalletsVaultWalletRoute
  SettingsWalletsIndexRoute: typeof SettingsWalletsIndexRoute
}

const SettingsWalletsRouteChildren: SettingsWalletsRouteChildren = {
  SettingsWalletsRevenueWalletRoute: SettingsWalletsRevenueWalletRoute,
  SettingsWalletsUserWalletRoute: SettingsWalletsUserWalletRoute,
  SettingsWalletsVaultWalletRoute: SettingsWalletsVaultWalletRoute,
  SettingsWalletsIndexRoute: SettingsWalletsIndexRoute,
}

const SettingsWalletsRouteWithChildren = SettingsWalletsRoute._addFileChildren(
  SettingsWalletsRouteChildren,
)

interface SettingsRouteChildren {
  SettingsAccountsRoute: typeof SettingsAccountsRoute
  SettingsApiRoute: typeof SettingsApiRoute
  SettingsNotificationsRoute: typeof SettingsNotificationsRoute
  SettingsProfileRoute: typeof SettingsProfileRoute
  SettingsTeamRoute: typeof SettingsTeamRoute
  SettingsWalletsRoute: typeof SettingsWalletsRouteWithChildren
  SettingsIndexRoute: typeof SettingsIndexRoute
}

const SettingsRouteChildren: SettingsRouteChildren = {
  SettingsAccountsRoute: SettingsAccountsRoute,
  SettingsApiRoute: SettingsApiRoute,
  SettingsNotificationsRoute: SettingsNotificationsRoute,
  SettingsProfileRoute: SettingsProfileRoute,
  SettingsTeamRoute: SettingsTeamRoute,
  SettingsWalletsRoute: SettingsWalletsRouteWithChildren,
  SettingsIndexRoute: SettingsIndexRoute,
}

const SettingsRouteWithChildren = SettingsRoute._addFileChildren(
  SettingsRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/convert': typeof ConvertRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/onboarding': typeof OnboardingRoute
  '/payout': typeof PayoutRoute
  '/settings': typeof SettingsRouteWithChildren
  '/signin': typeof SigninRoute
  '/tsuccess': typeof TsuccessRoute
  '/withdraw': typeof WithdrawRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/new-password': typeof AuthNewPasswordRoute
  '/auth/reset-password': typeof AuthResetPasswordRoute
  '/dashboard/api': typeof DashboardApiRoute
  '/dashboard/audit': typeof DashboardAuditRoute
  '/dashboard/overview': typeof DashboardOverviewRoute
  '/settings/accounts': typeof SettingsAccountsRoute
  '/settings/api': typeof SettingsApiRoute
  '/settings/notifications': typeof SettingsNotificationsRoute
  '/settings/profile': typeof SettingsProfileRoute
  '/settings/team': typeof SettingsTeamRoute
  '/settings/wallets': typeof SettingsWalletsRouteWithChildren
  '/signup/$sessionId': typeof SignupSessionIdRoute
  '/dashboard/': typeof DashboardIndexRoute
  '/settings/': typeof SettingsIndexRoute
  '/dashboard/wallet/rules': typeof DashboardWalletRulesRoute
  '/sa/signup/$sessionId': typeof SaSignupSessionIdRoute
  '/settings/wallets/revenue-wallet': typeof SettingsWalletsRevenueWalletRoute
  '/settings/wallets/user-wallet': typeof SettingsWalletsUserWalletRoute
  '/settings/wallets/vault-wallet': typeof SettingsWalletsVaultWalletRoute
  '/settings/wallets/': typeof SettingsWalletsIndexRoute
  '/dashboard/wallet/revenue/overview': typeof DashboardWalletRevenueOverviewRoute
  '/dashboard/wallet/revenue/requestPayout': typeof DashboardWalletRevenueRequestPayoutRoute
  '/dashboard/wallet/spend/addAccount': typeof DashboardWalletSpendAddAccountRoute
  '/dashboard/wallet/spend/fund': typeof DashboardWalletSpendFundRoute
  '/dashboard/wallet/spend/marketplace': typeof DashboardWalletSpendMarketplaceRoute
  '/dashboard/wallet/spend/paymentLinkSetup': typeof DashboardWalletSpendPaymentLinkSetupRoute
  '/dashboard/wallet/spend/setup': typeof DashboardWalletSpendSetupRoute
  '/dashboard/wallet/users/$user_id': typeof DashboardWalletUsersUseridRoute
  '/dashboard/wallet/spend': typeof DashboardWalletSpendIndexRoute
  '/dashboard/wallet/users': typeof DashboardWalletUsersIndexRoute
  '/dashboard/wallet/spend/subwallets/$id': typeof DashboardWalletSpendSubwalletsIdRoute
  '/dashboard/wallet/spend/token/$token': typeof DashboardWalletSpendTokenTokenRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/convert': typeof ConvertRoute
  '/onboarding': typeof OnboardingRoute
  '/payout': typeof PayoutRoute
  '/signin': typeof SigninRoute
  '/tsuccess': typeof TsuccessRoute
  '/withdraw': typeof WithdrawRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/new-password': typeof AuthNewPasswordRoute
  '/auth/reset-password': typeof AuthResetPasswordRoute
  '/dashboard/api': typeof DashboardApiRoute
  '/dashboard/audit': typeof DashboardAuditRoute
  '/dashboard/overview': typeof DashboardOverviewRoute
  '/settings/accounts': typeof SettingsAccountsRoute
  '/settings/api': typeof SettingsApiRoute
  '/settings/notifications': typeof SettingsNotificationsRoute
  '/settings/profile': typeof SettingsProfileRoute
  '/settings/team': typeof SettingsTeamRoute
  '/signup/$sessionId': typeof SignupSessionIdRoute
  '/dashboard': typeof DashboardIndexRoute
  '/settings': typeof SettingsIndexRoute
  '/dashboard/wallet/rules': typeof DashboardWalletRulesRoute
  '/sa/signup/$sessionId': typeof SaSignupSessionIdRoute
  '/settings/wallets/revenue-wallet': typeof SettingsWalletsRevenueWalletRoute
  '/settings/wallets/user-wallet': typeof SettingsWalletsUserWalletRoute
  '/settings/wallets/vault-wallet': typeof SettingsWalletsVaultWalletRoute
  '/settings/wallets': typeof SettingsWalletsIndexRoute
  '/dashboard/wallet/revenue/overview': typeof DashboardWalletRevenueOverviewRoute
  '/dashboard/wallet/revenue/requestPayout': typeof DashboardWalletRevenueRequestPayoutRoute
  '/dashboard/wallet/spend/addAccount': typeof DashboardWalletSpendAddAccountRoute
  '/dashboard/wallet/spend/fund': typeof DashboardWalletSpendFundRoute
  '/dashboard/wallet/spend/marketplace': typeof DashboardWalletSpendMarketplaceRoute
  '/dashboard/wallet/spend/paymentLinkSetup': typeof DashboardWalletSpendPaymentLinkSetupRoute
  '/dashboard/wallet/spend/setup': typeof DashboardWalletSpendSetupRoute
  '/dashboard/wallet/users/$user_id': typeof DashboardWalletUsersUseridRoute
  '/dashboard/wallet/spend': typeof DashboardWalletSpendIndexRoute
  '/dashboard/wallet/users': typeof DashboardWalletUsersIndexRoute
  '/dashboard/wallet/spend/subwallets/$id': typeof DashboardWalletSpendSubwalletsIdRoute
  '/dashboard/wallet/spend/token/$token': typeof DashboardWalletSpendTokenTokenRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/convert': typeof ConvertRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/onboarding': typeof OnboardingRoute
  '/payout': typeof PayoutRoute
  '/settings': typeof SettingsRouteWithChildren
  '/signin': typeof SigninRoute
  '/tsuccess': typeof TsuccessRoute
  '/withdraw': typeof WithdrawRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/new-password': typeof AuthNewPasswordRoute
  '/auth/reset-password': typeof AuthResetPasswordRoute
  '/dashboard/api': typeof DashboardApiRoute
  '/dashboard/audit': typeof DashboardAuditRoute
  '/dashboard/overview': typeof DashboardOverviewRoute
  '/settings/accounts': typeof SettingsAccountsRoute
  '/settings/api': typeof SettingsApiRoute
  '/settings/notifications': typeof SettingsNotificationsRoute
  '/settings/profile': typeof SettingsProfileRoute
  '/settings/team': typeof SettingsTeamRoute
  '/settings/wallets': typeof SettingsWalletsRouteWithChildren
  '/signup/$sessionId': typeof SignupSessionIdRoute
  '/dashboard/': typeof DashboardIndexRoute
  '/settings/': typeof SettingsIndexRoute
  '/dashboard/wallet/rules': typeof DashboardWalletRulesRoute
  '/sa/signup/$sessionId': typeof SaSignupSessionIdRoute
  '/settings/wallets/revenue-wallet': typeof SettingsWalletsRevenueWalletRoute
  '/settings/wallets/user-wallet': typeof SettingsWalletsUserWalletRoute
  '/settings/wallets/vault-wallet': typeof SettingsWalletsVaultWalletRoute
  '/settings/wallets/': typeof SettingsWalletsIndexRoute
  '/dashboard/wallet/revenue/overview': typeof DashboardWalletRevenueOverviewRoute
  '/dashboard/wallet/revenue/requestPayout': typeof DashboardWalletRevenueRequestPayoutRoute
  '/dashboard/wallet/spend/addAccount': typeof DashboardWalletSpendAddAccountRoute
  '/dashboard/wallet/spend/fund': typeof DashboardWalletSpendFundRoute
  '/dashboard/wallet/spend/marketplace': typeof DashboardWalletSpendMarketplaceRoute
  '/dashboard/wallet/spend/paymentLinkSetup': typeof DashboardWalletSpendPaymentLinkSetupRoute
  '/dashboard/wallet/spend/setup': typeof DashboardWalletSpendSetupRoute
  '/dashboard/wallet/users/$user_id': typeof DashboardWalletUsersUseridRoute
  '/dashboard/wallet/spend/': typeof DashboardWalletSpendIndexRoute
  '/dashboard/wallet/users/': typeof DashboardWalletUsersIndexRoute
  '/dashboard/wallet/spend/subwallets/$id': typeof DashboardWalletSpendSubwalletsIdRoute
  '/dashboard/wallet/spend/token/$token': typeof DashboardWalletSpendTokenTokenRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/convert'
    | '/dashboard'
    | '/onboarding'
    | '/payout'
    | '/settings'
    | '/signin'
    | '/tsuccess'
    | '/withdraw'
    | '/auth/forgot-password'
    | '/auth/new-password'
    | '/auth/reset-password'
    | '/dashboard/api'
    | '/dashboard/audit'
    | '/dashboard/overview'
    | '/settings/accounts'
    | '/settings/api'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/team'
    | '/settings/wallets'
    | '/signup/$sessionId'
    | '/dashboard/'
    | '/settings/'
    | '/dashboard/wallet/rules'
    | '/sa/signup/$sessionId'
    | '/settings/wallets/revenue-wallet'
    | '/settings/wallets/user-wallet'
    | '/settings/wallets/vault-wallet'
    | '/settings/wallets/'
    | '/dashboard/wallet/revenue/overview'
    | '/dashboard/wallet/revenue/requestPayout'
    | '/dashboard/wallet/spend/addAccount'
    | '/dashboard/wallet/spend/fund'
    | '/dashboard/wallet/spend/marketplace'
    | '/dashboard/wallet/spend/paymentLinkSetup'
    | '/dashboard/wallet/spend/setup'
    | '/dashboard/wallet/users/$user_id'
    | '/dashboard/wallet/spend'
    | '/dashboard/wallet/users'
    | '/dashboard/wallet/spend/subwallets/$id'
    | '/dashboard/wallet/spend/token/$token'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/convert'
    | '/onboarding'
    | '/payout'
    | '/signin'
    | '/tsuccess'
    | '/withdraw'
    | '/auth/forgot-password'
    | '/auth/new-password'
    | '/auth/reset-password'
    | '/dashboard/api'
    | '/dashboard/audit'
    | '/dashboard/overview'
    | '/settings/accounts'
    | '/settings/api'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/team'
    | '/signup/$sessionId'
    | '/dashboard'
    | '/settings'
    | '/dashboard/wallet/rules'
    | '/sa/signup/$sessionId'
    | '/settings/wallets/revenue-wallet'
    | '/settings/wallets/user-wallet'
    | '/settings/wallets/vault-wallet'
    | '/settings/wallets'
    | '/dashboard/wallet/revenue/overview'
    | '/dashboard/wallet/revenue/requestPayout'
    | '/dashboard/wallet/spend/addAccount'
    | '/dashboard/wallet/spend/fund'
    | '/dashboard/wallet/spend/marketplace'
    | '/dashboard/wallet/spend/paymentLinkSetup'
    | '/dashboard/wallet/spend/setup'
    | '/dashboard/wallet/users/$user_id'
    | '/dashboard/wallet/spend'
    | '/dashboard/wallet/users'
    | '/dashboard/wallet/spend/subwallets/$id'
    | '/dashboard/wallet/spend/token/$token'
  id:
    | '__root__'
    | '/'
    | '/convert'
    | '/dashboard'
    | '/onboarding'
    | '/payout'
    | '/settings'
    | '/signin'
    | '/tsuccess'
    | '/withdraw'
    | '/auth/forgot-password'
    | '/auth/new-password'
    | '/auth/reset-password'
    | '/dashboard/api'
    | '/dashboard/audit'
    | '/dashboard/overview'
    | '/settings/accounts'
    | '/settings/api'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/team'
    | '/settings/wallets'
    | '/signup/$sessionId'
    | '/dashboard/'
    | '/settings/'
    | '/dashboard/wallet/rules'
    | '/sa/signup/$sessionId'
    | '/settings/wallets/revenue-wallet'
    | '/settings/wallets/user-wallet'
    | '/settings/wallets/vault-wallet'
    | '/settings/wallets/'
    | '/dashboard/wallet/revenue/overview'
    | '/dashboard/wallet/revenue/requestPayout'
    | '/dashboard/wallet/spend/addAccount'
    | '/dashboard/wallet/spend/fund'
    | '/dashboard/wallet/spend/marketplace'
    | '/dashboard/wallet/spend/paymentLinkSetup'
    | '/dashboard/wallet/spend/setup'
    | '/dashboard/wallet/users/$user_id'
    | '/dashboard/wallet/spend/'
    | '/dashboard/wallet/users/'
    | '/dashboard/wallet/spend/subwallets/$id'
    | '/dashboard/wallet/spend/token/$token'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ConvertRoute: typeof ConvertRoute
  DashboardRoute: typeof DashboardRouteWithChildren
  OnboardingRoute: typeof OnboardingRoute
  PayoutRoute: typeof PayoutRoute
  SettingsRoute: typeof SettingsRouteWithChildren
  SigninRoute: typeof SigninRoute
  TsuccessRoute: typeof TsuccessRoute
  WithdrawRoute: typeof WithdrawRoute
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthNewPasswordRoute: typeof AuthNewPasswordRoute
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
  SignupSessionIdRoute: typeof SignupSessionIdRoute
  SaSignupSessionIdRoute: typeof SaSignupSessionIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ConvertRoute: ConvertRoute,
  DashboardRoute: DashboardRouteWithChildren,
  OnboardingRoute: OnboardingRoute,
  PayoutRoute: PayoutRoute,
  SettingsRoute: SettingsRouteWithChildren,
  SigninRoute: SigninRoute,
  TsuccessRoute: TsuccessRoute,
  WithdrawRoute: WithdrawRoute,
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthNewPasswordRoute: AuthNewPasswordRoute,
  AuthResetPasswordRoute: AuthResetPasswordRoute,
  SignupSessionIdRoute: SignupSessionIdRoute,
  SaSignupSessionIdRoute: SaSignupSessionIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/convert",
        "/dashboard",
        "/onboarding",
        "/payout",
        "/settings",
        "/signin",
        "/tsuccess",
        "/withdraw",
        "/auth/forgot-password",
        "/auth/new-password",
        "/auth/reset-password",
        "/signup/$sessionId",
        "/sa/signup/$sessionId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/convert": {
      "filePath": "convert.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx",
      "children": [
        "/dashboard/api",
        "/dashboard/audit",
        "/dashboard/overview",
        "/dashboard/",
        "/dashboard/wallet/rules",
        "/dashboard/wallet/revenue/overview",
        "/dashboard/wallet/revenue/requestPayout",
        "/dashboard/wallet/spend/addAccount",
        "/dashboard/wallet/spend/fund",
        "/dashboard/wallet/spend/marketplace",
        "/dashboard/wallet/spend/paymentLinkSetup",
        "/dashboard/wallet/spend/setup",
        "/dashboard/wallet/users/$user_id",
        "/dashboard/wallet/spend/",
        "/dashboard/wallet/users/",
        "/dashboard/wallet/spend/subwallets/$id",
        "/dashboard/wallet/spend/token/$token"
      ]
    },
    "/onboarding": {
      "filePath": "onboarding.tsx"
    },
    "/payout": {
      "filePath": "payout.tsx"
    },
    "/settings": {
      "filePath": "settings.tsx",
      "children": [
        "/settings/accounts",
        "/settings/api",
        "/settings/notifications",
        "/settings/profile",
        "/settings/team",
        "/settings/wallets",
        "/settings/"
      ]
    },
    "/signin": {
      "filePath": "signin.tsx"
    },
    "/tsuccess": {
      "filePath": "tsuccess.tsx"
    },
    "/withdraw": {
      "filePath": "withdraw.tsx"
    },
    "/auth/forgot-password": {
      "filePath": "auth.forgot-password.tsx"
    },
    "/auth/new-password": {
      "filePath": "auth.new-password.tsx"
    },
    "/auth/reset-password": {
      "filePath": "auth.reset-password.tsx"
    },
    "/dashboard/api": {
      "filePath": "dashboard/api.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/audit": {
      "filePath": "dashboard/audit.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/overview": {
      "filePath": "dashboard/overview.tsx",
      "parent": "/dashboard"
    },
    "/settings/accounts": {
      "filePath": "settings/accounts.tsx",
      "parent": "/settings"
    },
    "/settings/api": {
      "filePath": "settings/api.tsx",
      "parent": "/settings"
    },
    "/settings/notifications": {
      "filePath": "settings/notifications.tsx",
      "parent": "/settings"
    },
    "/settings/profile": {
      "filePath": "settings/profile.tsx",
      "parent": "/settings"
    },
    "/settings/team": {
      "filePath": "settings/team.tsx",
      "parent": "/settings"
    },
    "/settings/wallets": {
      "filePath": "settings/wallets.tsx",
      "parent": "/settings",
      "children": [
        "/settings/wallets/revenue-wallet",
        "/settings/wallets/user-wallet",
        "/settings/wallets/vault-wallet",
        "/settings/wallets/"
      ]
    },
    "/signup/$sessionId": {
      "filePath": "signup.$sessionId.tsx"
    },
    "/dashboard/": {
      "filePath": "dashboard/index.tsx",
      "parent": "/dashboard"
    },
    "/settings/": {
      "filePath": "settings/index.tsx",
      "parent": "/settings"
    },
    "/dashboard/wallet/rules": {
      "filePath": "dashboard/wallet/rules.tsx",
      "parent": "/dashboard"
    },
    "/sa/signup/$sessionId": {
      "filePath": "sa.signup.$sessionId.tsx"
    },
    "/settings/wallets/revenue-wallet": {
      "filePath": "settings/wallets/revenue-wallet.tsx",
      "parent": "/settings/wallets"
    },
    "/settings/wallets/user-wallet": {
      "filePath": "settings/wallets/user-wallet.tsx",
      "parent": "/settings/wallets"
    },
    "/settings/wallets/vault-wallet": {
      "filePath": "settings/wallets/vault-wallet.tsx",
      "parent": "/settings/wallets"
    },
    "/settings/wallets/": {
      "filePath": "settings/wallets/index.tsx",
      "parent": "/settings/wallets"
    },
    "/dashboard/wallet/revenue/overview": {
      "filePath": "dashboard/wallet/revenue/overview.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/revenue/requestPayout": {
      "filePath": "dashboard/wallet/revenue/requestPayout.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/addAccount": {
      "filePath": "dashboard/wallet/spend/addAccount.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/fund": {
      "filePath": "dashboard/wallet/spend/fund.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/marketplace": {
      "filePath": "dashboard/wallet/spend/marketplace.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/paymentLinkSetup": {
      "filePath": "dashboard/wallet/spend/paymentLinkSetup.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/setup": {
      "filePath": "dashboard/wallet/spend/setup.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/users/$user_id": {
      "filePath": "dashboard/wallet/users.$user_id.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/": {
      "filePath": "dashboard/wallet/spend/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/users/": {
      "filePath": "dashboard/wallet/users/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/subwallets/$id": {
      "filePath": "dashboard/wallet/spend/subwallets.$id.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/wallet/spend/token/$token": {
      "filePath": "dashboard/wallet/spend/token.$token.tsx",
      "parent": "/dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
