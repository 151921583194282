import { GlobalStateProvider, useGlobalState } from "~/contexts/global-state";
import { TrxLog } from "~/libs/factories/transaction";

// Usage:
// const h = useModal();
// h.show("add_user", { id: "kelvin" });
// h.show("add_user", { name: "Matthew" });
// h.hide("add_user");
// h.toggle("weakling", { name: "" });
// h.toggle("weakling", { id: "Weak" });
// h.toggle("weakling");

export type EventsMap = {
  add_user: { name: string };
  ban_user: { user_id: string };
  create_role: undefined;
  invite_user: undefined;
  create_modal: undefined;
  create_api_key: {
    client_id: string;
    client_secret: string;
    mode: "rotate" | "fresh";
  };
  verify_email: { email: string };
  "prompt:rotate": undefined;
  add_web_hook:
    | {
        mode: "edit";
        data: { id: string; url: string };
      }
    | {
        mode: "create";
      };
  request_payout: { user_id: string };
  suspend_user: { user_id: string };
  transaction_details: TrxLog;
  new_payment_link: undefined;
};

type ModalAPI<TKey, TData> = {
  show: (data: TData) => void;
  hide: () => void;
  toggle: (data: TData) => void;
  isVisible: boolean;
  key: TKey;
  data: TData;
};

export type ModalKeys = keyof EventsMap;

function useModal() {
  return useGlobalState<EventsMap>();
}

function useModalHandle<TKey extends keyof EventsMap, D = EventsMap[TKey]>(
  key: TKey,
): ModalAPI<TKey, D> {
  const modal = useGlobalState<EventsMap>();

  return Object.assign(
    {},
    {
      key,
      // @ts-expect-error
      show: (data: D) => modal.show<TKey>(key, data),
      hide: () => modal.hide(key as TKey),
      // @ts-expect-error
      toggle: (data: D) => modal.toggle(key, data),
      data: modal.params.get(key),
      isVisible: modal.store[key] === true,
    },
  ) as unknown as ModalAPI<TKey, D>;
}

function useModalState(key: keyof EventsMap): boolean {
  return useGlobalState<EventsMap>().store[key];
}

const ModalProvider = GlobalStateProvider;

export { useModal, useModalHandle, ModalProvider, useModalState };
