import { Match } from "effect";
import { ToastOptions, toast as _toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolveFetchResponse } from "~/api/client";
import { ResponseType } from "~/api/response-types";

type AlertType = "success" | "error" | "warning";

export const notify = (
  type: AlertType,
  message: string,
  config?: ToastOptions,
) => {
  ShowToast(message, type, config);
};

export const notifySuccess = (response: unknown) => {
  const fn = ResponseType.pipe(
    Match.tag("Okay", (data) => {
      // @ts-expect-error
      return data?.value?.message ?? "Action Successful";
    }),
    Match.orElse(() => "Action Successful"),
  );

  notify("success", fn(resolveFetchResponse(response)));
};

export const notifyApiError = ResponseType.pipe(
  Match.tag("ApiError", (data) => {
    notify("error", "Incorrect business email or password"); //was data.message before - should be changed like this since it comes from cognito
  }),
  Match.tag("ValidationError", (data) => {
    notify("error", data.messages[0]);
  }),
  Match.tag("UnknownError", (data) => {
    notify("error", data.message);
  }),
  Match.tag("Okay", () => {}),
  Match.orElse((err) => {
    console.error(">>> orElse Error", { type: typeof err, err });

    notify("error", "Something unexpected happened!");
  }),
);

export default function ShowToast(
  message: string,
  type: AlertType,
  config?: ToastOptions,
) {
  if (type === "success") {
    _toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "error") {
    _toast.error(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "warning") {
    _toast.warning(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}
