import React from "react";
import { OnboardingOne } from "./OnboardingOne";
import { OnboardingTwo } from "./OnboardingTwo";

import { ToOptions, useNavigate, useSearch } from "@tanstack/react-router";
import { lensProp, set } from "ramda";
import { flushSync } from "react-dom";
import useEffectEvent from "react-use-event-hook";
import { cn } from "~/@/lib/utils";
import { useCreateBusinessAndInviteASelfOwner } from "~/api/codegen/walletosComponents";
import { FooterBackArrow } from "~/assets/icons/FooterBackArrow";
import { notifyApiError } from "~/components/Toast";
import { Header4Medium } from "~/components/Typography";
import UserProgress from "~/components/UserProgress";
import Layout, {
  OnboardingHeading,
  OnboardingSubHeading,
} from "~/containers/Onboarding/Layout";
import { usePersistedState } from "~/hooks/use-form-persist";
import { safeNum } from "~/libs/data.helper";
import { FormState, mergeStateWithTransformers } from "./helpers";

export default function Onboarding() {
  const { page: _page = 1 } = useSearch({ strict: true, from: "/onboarding" });
  const page = minmax(1, Components.length, safeNum(_page, 0));
  const navigate = useNavigate();
  const form_index = page - 1;

  const {
    data: persisted_form_state,
    setData,
    clear,
  } = usePersistedState<FormState[]>("onbaording_form", []);
  const { isPending, mutateAsync } = useCreateBusinessAndInviteASelfOwner();

  // functions
  const prev = useEffectEvent(function prev() {
    if (page <= 1) {
      return;
    }

    return navigate({
      search: {
        page: page - 1,
      },
    } as unknown as ToOptions);
  });

  const next = useEffectEvent(function next(new_data) {
    const addData = (state: FormState[]): FormState[] =>
      set(lensProp(form_index), new_data, state);

    const is_submit = form_index >= 1;

    if (!is_submit) {
      setData(addData);

      flushSync(() => {
        navigate({
          search: {
            page: page + 1,
          },
        } as unknown as ToOptions);
      });

      return;
    }

    onCreateAccount(addData(persisted_form_state));
  });

  const onCreateAccount = useEffectEvent(async function onCreateAccount(
    state: FormState[],
  ) {
    try {
      const res = await mutateAsync({
        body: mergeStateWithTransformers(state),
      });
      clear();
      return navigate({ to: `/sa/signup/${res.session}` });
    } catch (err) {
      notifyApiError(err);
    }
  });

  // Render
  const Component = Components[form_index];

  return (
    <Layout>
      <div className={"container mx-auto flex flex-col px-12"}>
        <div className={"flex flex-col px-2 md:px-28"}>
          <div className={"flex justify-between"}>
            <OnboardingBackArrow onClick={() => prev()} />
            <UserProgress stages={Components.length} progress={page} />
          </div>

          <div className="mt-[40px] space-y-4 border-b border-zinc-200 py-4">
            <OnboardingHeading.Outlet>
              <Header4Medium />
            </OnboardingHeading.Outlet>
            <OnboardingSubHeading.Outlet>
              <p className={"text-sm text-muted-foreground"} />
            </OnboardingSubHeading.Outlet>
          </div>

          <div className={"mt-8"}>
            <Component
              initialFormState={persisted_form_state[form_index]}
              next={next}
              prev={prev}
              isLoading={isPending}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Components = [
  OnboardingOne,
  OnboardingTwo,
  // OnboardingThree,
  // OnboardingFour,
];

export function OnboardingBackArrow(props: React.ComponentProps<"button">) {
  return (
    <button
      type={"button"}
      {...props}
      className={cn("cursor-pointer ", props.className)}
    >
      <FooterBackArrow />
    </button>
  );
}

const minmax = (start: number, end: number, num: number) => {
  if (num > end) {
    return end;
  }
  if (num < start) {
    return start;
  }
  return num;
};
